import { Alert, Grid } from "@emburse/embark-core";
import { useTranslation } from "react-i18next";
import { Header } from "components";
import React from "react";


/**
 * This component will redirect the user to the error page.
 */
export const ConfigurationError = () => {

    const {t} = useTranslation();

    return <>
        <Header/>
        <Grid container alignItems='center'>
            <Grid item xs={2}>
            </Grid>

            <Grid item xs={8}>
                <Alert data-qa="configuration-error-message"
                       verticalSpacingPosition={"top"}
                       verticalSpacingSize={7}
                       severity={'error'}>{t('fetchConfigurationErrorMessage')}</Alert>
            </Grid>

            <Grid item xs={2}>
            </Grid>
        </Grid>
    </>
};
