import { Button, Grid, Icon, Spacer } from "@emburse/embark-core";
import { ArrowBackIcon, ArrowForwardIcon } from "@emburse/embark-icons";
import { RouteLink } from "enums";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ConfigurationContext } from "providers";
import { lodash } from "utils";

export interface BottomNavBarProps {
    prevDataQA?: string;
    prevText?: string;
    prevRoutes?: RouteLink;
    nextDataQA?: string;
    nextText?: string;
    nextRoutes?: RouteLink;
}

export const BottomNavBar = (props: BottomNavBarProps) => {
    const navigate = useNavigate();
    const {isInsideEmbark} = React.useContext(ConfigurationContext);
    const spacerHeight = `${isInsideEmbark ? 24 : 105}px`;

    return (
        <>
            <Grid container data-qa="navigation-page" alignItems='center' justifyContent='space-between'>
                <Grid item>
                    {!lodash.isNil(props.prevRoutes) &&
                        <Button id="back-navigation"
                                data-qa={props.prevDataQA}
                                variant="text"
                                style={{float: "left"}}
                                startIcon={<Icon iconUrl={ArrowBackIcon}/>}
                                onClick={() => {
                                    navigate(props.prevRoutes!);
                                }}>
                            {props.prevText}
                        </Button>
                    }
                </Grid>
                <Grid item>
                    {!lodash.isNil(props.nextRoutes) &&
                    <Button id="next-navigation"
                            data-qa={props.nextDataQA}
                            variant="text"
                            endIcon={<Icon iconUrl={ArrowForwardIcon}/>}
                            style={{float: "right"}}
                            onClick={() => {
                                navigate(props.nextRoutes!);
                            }}>
                        {props.nextText}
                    </Button>
                    }
                </Grid>
            </Grid>
            <Spacer size={spacerHeight} />
        </>
    )
}