import React from 'react'
import {
    Autocomplete,
    Box,
    Checkbox,
    FormControlLabel,
    Radio,
    Switch,
    TextField,
    Typography
} from "@emburse/embark-core";
import {useTranslation} from "react-i18next";
import {CustomerAndProjectSettings, RouteLink} from "enums";
import {BottomNavBar, CheckBoxTypography, SwitchTypography, ViewContainer} from "components";
import secureLocalStorage from "react-secure-storage";
import {ConfigurationContext} from "providers";
import {
    getCustomersAndProjectsFromLocalStorage,
    getDefaultValue,
    getHelperText,
    getSyncClassFromLocalStorage,
    getSyncDepartmentFromLocalStorage,
    getSyncLocationFromLocalStorage,
    getValueByKey,
    textFieldPattern
} from "utils";
import {Controller, useForm, useWatch} from "react-hook-form";
import {ICustomers, IProjects} from "models";

export const CustomersAndProjects = () => {
    const {t} = useTranslation();
    const {setConfiguration, configurationId, companyId} = React.useContext(ConfigurationContext);

    const customersAndProjectsFromLocalStorage = getCustomersAndProjectsFromLocalStorage(companyId, configurationId);
    const [syncCustomers, setSyncCustomers] = React.useState<boolean>(customersAndProjectsFromLocalStorage.syncCustomersEnabled as boolean);
    const [syncProjects, setSyncProjects] = React.useState<boolean>(customersAndProjectsFromLocalStorage.syncProjectsEnabled as boolean);

    const syncDepartment = getSyncDepartmentFromLocalStorage(companyId, configurationId);
    const syncClass = getSyncClassFromLocalStorage(companyId, configurationId);
    const syncLocation = getSyncLocationFromLocalStorage(companyId, configurationId);

    const {
        register,
        clearErrors,
        control,
        formState,
        setValue,
        setError,
        watch,
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            customerSettings: {
                useCustomFieldToFilterCustomersEnabled: customersAndProjectsFromLocalStorage.customerSettings?.useCustomFieldToFilterCustomersEnabled,
                filterByCustomFieldEnabled: customersAndProjectsFromLocalStorage.customerSettings?.filterByCustomFieldEnabled,
                ignoreByCustomFieldEnabled: customersAndProjectsFromLocalStorage.customerSettings?.ignoreByCustomFieldEnabled,
                filterByCustomField: customersAndProjectsFromLocalStorage.customerSettings?.filterByCustomField,
                ignoreByCustomField: customersAndProjectsFromLocalStorage.customerSettings?.ignoreByCustomField,
                filterByCustomerStatus: customersAndProjectsFromLocalStorage.customerSettings?.filterByCustomerStatus
            },
            projectSettings: {
                useCustomFieldToFilterProjectsEnabled: customersAndProjectsFromLocalStorage.projectSettings?.useCustomFieldToFilterProjectsEnabled,
                filterByCustomFieldEnabled: customersAndProjectsFromLocalStorage.projectSettings?.filterByCustomFieldEnabled,
                ignoreByCustomFieldEnabled: customersAndProjectsFromLocalStorage.projectSettings?.ignoreByCustomFieldEnabled,
                filterByCustomField: customersAndProjectsFromLocalStorage.projectSettings?.filterByCustomField,
                ignoreByCustomField: customersAndProjectsFromLocalStorage.projectSettings?.ignoreByCustomField,
                filterByProjectStatus: customersAndProjectsFromLocalStorage.projectSettings?.filterByProjectStatus,
                synchronizeProjectResources: customersAndProjectsFromLocalStorage.projectSettings?.synchronizeProjectResources,
                department: customersAndProjectsFromLocalStorage.projectSettings?.department,
                clazz: customersAndProjectsFromLocalStorage.projectSettings?.clazz,
                location: customersAndProjectsFromLocalStorage.projectSettings?.location
            }
        }
    });

    const values = useWatch({control});
    const {errors} = formState;

    const syncCustomersChecked = (value: any) => {
        const syncCustomersChecked = value.target.checked;
        setSyncCustomers(syncCustomersChecked);

        if(!syncCustomersChecked) {
            setValue("customerSettings.useCustomFieldToFilterCustomersEnabled", false);
            setValue("customerSettings.filterByCustomFieldEnabled", false);
            setValue("customerSettings.ignoreByCustomFieldEnabled", false);
            setValue("customerSettings.filterByCustomField", "");
            setValue("customerSettings.ignoreByCustomField", "");
            setValue("customerSettings.filterByCustomerStatus", []);

            clearErrors("customerSettings.filterByCustomField");
            clearErrors("customerSettings.ignoreByCustomField");

            saveCustomersSettingsInLocalStorage(values.customerSettings as ICustomers);
        }
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.SYNC_CUSTOMERS, syncCustomersChecked);

        setConfiguration({
            customerAndProject: {
                syncCustomersEnabled: syncCustomersChecked,
                syncProjectsEnabled: syncProjects,
                customerSettings: values.customerSettings,
                projectSettings: values.projectSettings
            }
        })
    }

    React.useEffect(() => {
        saveCustomersSettingsInLocalStorage(values.customerSettings as ICustomers);
        saveProjectsSettingsInLocalStorage(values.projectSettings as IProjects);
        setConfiguration({
            customerAndProject: {
                syncCustomersEnabled: syncCustomers,
                syncProjectsEnabled: syncProjects,
                customerSettings: values.customerSettings,
                projectSettings: values.projectSettings
            }
        })
    }, [values]);// eslint-disable-line react-hooks/exhaustive-deps

    const saveCustomersSettingsInLocalStorage = (value: ICustomers) => {
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.CUSTOMERS_USE_CUSTOM_FIELD_TO_FILTER_ENABLED, value.useCustomFieldToFilterCustomersEnabled as boolean);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.CUSTOMERS_FILTER_BY_CUSTOM_FIELD_ENABLED, value.filterByCustomFieldEnabled as boolean);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.CUSTOMERS_IGNORE_BY_CUSTOM_FIELD_ENABLED, value.ignoreByCustomFieldEnabled as boolean);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.CUSTOMERS_FILTER_BY_CUSTOM_FIELD, value.filterByCustomField as string);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.CUSTOMERS_IGNORE_BY_CUSTOM_FIELD, value.ignoreByCustomField as string);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.CUSTOMERS_FILTER_BY_CUSTOMER_STATUS, value.filterByCustomerStatus as string[]);
    };

    const syncProjectsChecked = (value: any) => {
        const syncProjectsChecked = value.target.checked;
        setSyncProjects(syncProjectsChecked);

        if(!syncProjectsChecked) {
            setValue("projectSettings.useCustomFieldToFilterProjectsEnabled", false);
            setValue("projectSettings.filterByCustomFieldEnabled", false);
            setValue("projectSettings.ignoreByCustomFieldEnabled", false);
            setValue("projectSettings.filterByCustomField", "");
            setValue("projectSettings.ignoreByCustomField", "");
            setValue("projectSettings.filterByProjectStatus", []);
            setValue("projectSettings.synchronizeProjectResources", "");
            setValue("projectSettings.department", "");
            setValue("projectSettings.clazz", "");
            setValue("projectSettings.location", "");

            clearErrors("projectSettings.filterByCustomField");
            clearErrors("projectSettings.ignoreByCustomField");
            clearErrors("projectSettings.department");
            clearErrors("projectSettings.clazz");
            clearErrors("projectSettings.location");

            saveProjectsSettingsInLocalStorage(values.projectSettings as IProjects);
        }
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.SYNC_PROJECTS, syncProjectsChecked);

        setConfiguration({
            customerAndProject: {
                syncCustomersEnabled: syncCustomers,
                syncProjectsEnabled: syncProjectsChecked,
                customerSettings: values.customerSettings,
                projectSettings: values.projectSettings
            }
        })
    }

    const saveProjectsSettingsInLocalStorage = (value: IProjects) => {
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.PROJECTS_USE_CUSTOM_FIELD_TO_FILTER_ENABLED, value.useCustomFieldToFilterProjectsEnabled as boolean);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.PROJECTS_FILTER_BY_CUSTOM_FIELD_ENABLED, value.filterByCustomFieldEnabled as boolean);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.PROJECTS_IGNORE_BY_CUSTOM_FIELD_ENABLED, value.ignoreByCustomFieldEnabled as boolean);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.PROJECTS_FILTER_BY_CUSTOM_FIELD, value.filterByCustomField as string);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.PROJECTS_IGNORE_BY_CUSTOM_FIELD, value.ignoreByCustomField as string);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.PROJECTS_FILTER_BY_PROJECT_STATUS, value.filterByProjectStatus as string[]);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.PROJECTS_SYNCHRONIZE_PROJECT_RESOURCES, value.synchronizeProjectResources as string);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.PROJECTS_DEPARTMENT, value.department as string);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.PROJECTS_CLASS, value.clazz as string);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.PROJECTS_LOCATION, value.location as string);
    };

    const handleOnChangeFilterByCustomerStatus = (_event: React.ChangeEvent<{}>, newValue: any) => {
        setValue("customerSettings.filterByCustomerStatus", newValue);
    };

    const handleOnChangeFilterByProjectStatus = (_event: React.ChangeEvent<{}>, newValue: any) => {
        setValue("projectSettings.filterByProjectStatus", newValue);
    };

    return <>
        <ViewContainer data-qa="customers-and-projects-page">
            <Typography verticalSpacingSize={1} variant="h4"
                        data-qa="title-customers-and-projects">{t('customersAndProjects')}</Typography>
            <Typography verticalSpacingSize={6}
                        data-qa="sub-title-customers-and-projects">{t('subTitleProjectsCustomers')}</Typography>

            <Box verticalSpacingSize={6}>
                <FormControlLabel
                    data-qa="sync-customers"
                    checked={syncCustomers}
                    control={<Switch data-qa="switch-sync-customers" color="primary"/>}
                    verticalSpacingSize={1}
                    onChange={(event) => {
                        syncCustomersChecked(event);
                    }}
                    label={<Typography variant="h5">{t('syncCustomers')}</Typography>}/>

                <SwitchTypography data-qa="sync-customers-sub-title-customers"
                                  value={t('syncCustomersSubTitleCustomers')}/>

                {syncCustomers && <Box className="switchLeftMargin widthField">
                    <form data-qa="customers-form" onSubmit={(e) => {
                        e.preventDefault()
                    }}>
                        <FormControlLabel
                            verticalSpacingSize={2}
                            verticalSpacingPosition="top"
                            data-qa="use-custom-field-to-filter-customers"
                            checked={values.customerSettings?.useCustomFieldToFilterCustomersEnabled}
                            control={<Checkbox data-qa="checkbox-use-custom-field-to-filter-customers"
                                               color="primary"/>}
                            onChange={(event) => {
                                const value: any = event;
                                setValue("customerSettings.useCustomFieldToFilterCustomersEnabled", value.target.checked);
                                if(value.target.checked) {
                                    setValue("customerSettings.filterByCustomFieldEnabled", true);
                                    setValue("customerSettings.ignoreByCustomFieldEnabled", false);
                                } else {
                                    setValue("customerSettings.filterByCustomFieldEnabled", false);
                                    setValue("customerSettings.ignoreByCustomFieldEnabled", false);
                                    clearErrors("customerSettings.filterByCustomField");
                                    clearErrors("customerSettings.ignoreByCustomField");
                                }
                            }}
                            label={<Typography
                                variant="subtitle1">{t('useCustomFieldToFilterCustomers')}</Typography>}/>

                        <CheckBoxTypography data-qa="use-custom-field-to-filter-customers-helper-text"
                                            value={t('useCustomFieldToFilterCustomersHelperText')}/>

                        {values.customerSettings?.useCustomFieldToFilterCustomersEnabled &&
                            <Box data-qa="custom-field-customers"
                                 className="checkboxLeftMargin"
                                 verticalSpacingSize={2}
                                 verticalSpacingPosition={"top"}>
                                <FormControlLabel
                                    data-qa="filter-by-custom-field-customers"
                                    checked={values.customerSettings?.filterByCustomFieldEnabled}
                                    control={<Radio
                                        data-qa="radio-filter-by-custom-field-customers"
                                        name="customerSettings.filterByCustomFieldEnabled"
                                        color="primary"
                                        checked={values.customerSettings?.filterByCustomFieldEnabled}
                                        onChange={() => {
                                            setValue("customerSettings.filterByCustomFieldEnabled", true);
                                            setValue("customerSettings.ignoreByCustomFieldEnabled", false);
                                            clearErrors("customerSettings.ignoreByCustomField");
                                        }}
                                    />}
                                    label={<Typography variant="body1">{t('filterByCustomField')}</Typography>}/>

                                <FormControlLabel
                                    data-qa="ignore-by-custom-field-customers"
                                    checked={values.customerSettings?.ignoreByCustomFieldEnabled}
                                    control={<Radio
                                        data-qa="radio-ignore-by-custom-field-customers"
                                        name="customerSettings.ignoreByCustomFieldEnabled"
                                        color="primary"
                                        checked={values.customerSettings?.ignoreByCustomFieldEnabled}
                                        onChange={() => {
                                            setValue("customerSettings.filterByCustomFieldEnabled", false);
                                            setValue("customerSettings.ignoreByCustomFieldEnabled", true);
                                            clearErrors("customerSettings.filterByCustomField");
                                        }}
                                    />}
                                    label={<Typography variant="body1">{t('ignoreByCustomField')}</Typography>}/>

                                {values.customerSettings?.filterByCustomFieldEnabled &&
                                    <TextField id="filter-by-custom-field-customers"
                                               data-qa="filter-by-custom-field-customers-text-field"
                                               inputProps={{"data-qa": "inner-filter-by-custom-field-customers-text-field"}}
                                               type="text"
                                               verticalSpacingSize={2}
                                               verticalSpacingPosition={"top"}
                                               label={t('filterByCustomField')}
                                               fullWidth
                                               required
                                               {...register('customerSettings.filterByCustomField', {
                                                   required: true,
                                                   validate: {
                                                       Validate(value) {
                                                           return (value !== undefined && value?.trim().length !== 0 && !textFieldPattern.test(value));
                                                       }
                                                   },
                                                   onBlur(event: any) {
                                                       if(event.target.value.length === 0) {
                                                           setError('customerSettings.filterByCustomField', {type: "required"});
                                                       } else if(textFieldPattern.test(event.target.value)) {
                                                           setError('customerSettings.filterByCustomField', {type: "pattern"});
                                                           setValue('customerSettings.filterByCustomField', "");
                                                       }
                                                   }
                                               })
                                               }
                                               InputLabelProps={{shrink: !!watch('customerSettings.filterByCustomField')}}
                                               FormHelperTextProps={{'data-qa': 'filter-by-custom-field-customers-helperText'}}
                                               helperText={getHelperText(t('filterByCustomFieldEnabledCustomersHelperText'), t('filterByCustomFieldEnabledCustomersWarningMessage'), t('textFieldValidationMessage'), errors.customerSettings?.filterByCustomField)}
                                               error={!!errors.customerSettings?.filterByCustomField}/>
                                }
                                {values.customerSettings?.ignoreByCustomFieldEnabled &&
                                    <TextField id="ignore-by-custom-field-customers"
                                               data-qa="ignore-by-custom-field-customers-text-field"
                                               inputProps={{"data-qa": "inner-ignore-by-custom-field-customers-text-field"}}
                                               type="text"
                                               verticalSpacingSize={2}
                                               verticalSpacingPosition={"top"}
                                               label={t('ignoreByCustomField')}
                                               fullWidth
                                               required
                                               {...register('customerSettings.ignoreByCustomField', {
                                                   required: true,
                                                   validate: {
                                                       Validate(value) {
                                                           return (value !== undefined && value?.trim().length !== 0 && !textFieldPattern.test(value));
                                                       }
                                                   },
                                                   onBlur(event: any) {
                                                       if(event.target.value.length === 0) {
                                                           setError('customerSettings.ignoreByCustomField', {type: "required"});
                                                       } else if(textFieldPattern.test(event.target.value)) {
                                                           setError('customerSettings.ignoreByCustomField', {type: "pattern"});
                                                           setValue('customerSettings.ignoreByCustomField', "");
                                                       }
                                                   }
                                               })}
                                               InputLabelProps={{shrink: !!watch('customerSettings.ignoreByCustomField')}}
                                               FormHelperTextProps={{'data-qa': 'ignore-by-custom-field-customers-helperText'}}
                                               helperText={getHelperText(t('ignoreByCustomFieldEnabledCustomersHelperText'), t('ignoreByCustomFieldEnabledCustomersWarningMessage'), t('textFieldValidationMessage'), errors.customerSettings?.ignoreByCustomField)}
                                               error={!!errors.customerSettings?.ignoreByCustomField}/>
                                }
                            </Box>}

                        <Autocomplete id="filter-by-customer-status-customers"
                                      data-qa="filter-by-customer-status-customers"
                                      onChange={handleOnChangeFilterByCustomerStatus}
                                      verticalSpacingSize={3}
                                      verticalSpacingPosition={"top"}
                                      multiple
                                      limitTags={5}
                                      options={values.customerSettings?.filterByCustomerStatus}
                                      autoHighlight
                                      value={values.customerSettings?.filterByCustomerStatus}
                                      filterOptions={(options, params) => {
                                          const filtered: string[] = [];
                                          if(params.inputValue !== '' && !params.inputValue.includes(' ') && !textFieldPattern.test(params.inputValue)) {
                                              filtered.push(
                                                  params.inputValue.toLowerCase()
                                              );
                                          }
                                          return filtered;
                                      }}
                                      label={t('filterByCustomerStatus')}
                                      name="customerSettings.filterByCustomerStatus"
                                      customText={{noOptionsText: t('multipleTextFieldValidationMessage')}}
                                      slotProps={{
                                          clearIndicator: {'data-qa': 'filter-by-customer-status-clear-indicator'},
                                          popupIndicator: {'data-qa': 'filter-by-customer-status-popup-indicator'},
                                          popper: {'data-qa': 'filter-by-customer-status-popper'},
                                          paper: {'data-qa': 'filter-by-customer-status-paper'}
                                      }}
                                      FormHelperTextProps={{'data-qa': 'filter-by-customer-status-helperText'}}
                                      helperText={t('filterByCustomerStatusHelperText')}/>

                    </form>
                </Box>}
            </Box>

            <Box verticalSpacingSize={3}>
                <FormControlLabel
                    data-qa="sync-projects"
                    checked={syncProjects}
                    control={<Switch data-qa="switch-sync-projects" color="primary"/>}
                    verticalSpacingSize={1}
                    onChange={(event) => {
                        syncProjectsChecked(event)
                    }}
                    label={<Typography variant="h5">{t('syncProjects')}</Typography>}/>

                <SwitchTypography data-qa="sync-projects-sub-title-projects" value={t('syncProjectsSubTitleProjects')}/>

                {syncProjects && <Box className="switchLeftMargin widthField">
                    <form data-qa="projects-form" onSubmit={(e) => {
                        e.preventDefault()
                    }}>
                        <FormControlLabel
                            verticalSpacingSize={2}
                            verticalSpacingPosition="top"
                            data-qa="use-custom-field-to-filter-projects"
                            checked={values.projectSettings?.useCustomFieldToFilterProjectsEnabled}
                            control={<Checkbox data-qa="checkbox-use-custom-field-to-filter-projects" color="primary"/>}
                            onChange={(event) => {
                                const value: any = event;
                                setValue("projectSettings.useCustomFieldToFilterProjectsEnabled", value.target.checked);
                                if(value.target.checked) {
                                    setValue("projectSettings.filterByCustomFieldEnabled", true);
                                    setValue("projectSettings.ignoreByCustomFieldEnabled", false);

                                } else {
                                    setValue("projectSettings.filterByCustomFieldEnabled", false);
                                    setValue("projectSettings.ignoreByCustomFieldEnabled", false);
                                    clearErrors("projectSettings.filterByCustomField");
                                    clearErrors("projectSettings.ignoreByCustomField");
                                }
                            }}
                            label={<Typography variant="subtitle1">{t('useCustomFieldToFilterProjects')}</Typography>}/>

                        <CheckBoxTypography data-qa="use-custom-field-to-filter-projects-helper-text"
                                            value={t('useCustomFieldToFilterProjectsHelperText')}/>

                        {values.projectSettings?.useCustomFieldToFilterProjectsEnabled &&
                            <Box data-qa="custom-field-projects"
                                 className="checkboxLeftMargin"
                                 verticalSpacingSize={2}
                                 verticalSpacingPosition={"top"}>
                                <FormControlLabel
                                    data-qa="filter-by-custom-field-projects"
                                    checked={values.projectSettings?.filterByCustomFieldEnabled}
                                    control={<Radio
                                        data-qa="radio-filter-by-custom-field-projects"
                                        name="projectSettings.filterByCustomFieldEnabled"
                                        color="primary"
                                        checked={values.projectSettings?.filterByCustomFieldEnabled}
                                        onChange={() => {
                                            setValue("projectSettings.filterByCustomFieldEnabled", true);
                                            setValue("projectSettings.ignoreByCustomFieldEnabled", false);
                                            clearErrors("projectSettings.ignoreByCustomField");
                                        }}
                                    />}
                                    label={<Typography variant="body1">{t('filterByCustomField')}</Typography>}/>

                                <FormControlLabel
                                    data-qa="ignore-by-custom-field-projects"
                                    checked={values.projectSettings?.ignoreByCustomFieldEnabled}
                                    control={<Radio
                                        data-qa="radio-ignore-by-custom-field-projects"
                                        name="projectSettings.ignoreByCustomFieldEnabled"
                                        color="primary"
                                        checked={values.projectSettings?.ignoreByCustomFieldEnabled}
                                        onChange={() => {
                                            setValue("projectSettings.filterByCustomFieldEnabled", false);
                                            setValue("projectSettings.ignoreByCustomFieldEnabled", true);
                                            clearErrors("projectSettings.filterByCustomField");
                                        }}
                                    />}
                                    label={<Typography variant="body1">{t('ignoreByCustomField')}</Typography>}/>

                                {values.projectSettings?.filterByCustomFieldEnabled &&
                                    <TextField id="filter-by-custom-field-projects"
                                               data-qa="filter-by-custom-field-projects-text-field"
                                               inputProps={{"data-qa": "inner-filter-by-custom-field-projects-text-field"}}
                                               type="text"
                                               verticalSpacingSize={2}
                                               verticalSpacingPosition={"top"}
                                               label={t('filterByCustomField')}
                                               fullWidth
                                               required
                                               {...register('projectSettings.filterByCustomField', {
                                                   required: true,
                                                   validate: {
                                                       Validate(value) {
                                                           return (value !== undefined && value?.trim().length !== 0 && !textFieldPattern.test(value));
                                                       }
                                                   },
                                                   onBlur(event: any) {
                                                       if(event.target.value.length === 0) {
                                                           setError('projectSettings.filterByCustomField', {type: "required"});
                                                       } else if(textFieldPattern.test(event.target.value)) {
                                                           setError('projectSettings.filterByCustomField', {type: "pattern"});
                                                           setValue('projectSettings.filterByCustomField', "");
                                                       }
                                                   }
                                               })
                                               }
                                               InputLabelProps={{shrink: !!watch('projectSettings.filterByCustomField')}}
                                               FormHelperTextProps={{'data-qa': 'filter-by-custom-field-projects-helperText'}}
                                               helperText={getHelperText(t('filterByCustomFieldEnabledProjectsHelperText'), t('filterByCustomFieldEnabledProjectsWarningMessage'), t('textFieldValidationMessage'), errors.projectSettings?.filterByCustomField)}
                                               error={!!errors.projectSettings?.filterByCustomField}/>
                                }

                                {values.projectSettings?.ignoreByCustomFieldEnabled &&
                                    <TextField id="ignore-by-custom-field-projects"
                                               data-qa="ignore-by-custom-field-projects-text-field"
                                               inputProps={{"data-qa": "inner-ignore-by-custom-field-projects-text-field"}}
                                               type="text"
                                               verticalSpacingSize={2}
                                               verticalSpacingPosition={"top"}
                                               label={t('ignoreByCustomField')}
                                               fullWidth
                                               required
                                               {...register('projectSettings.ignoreByCustomField', {
                                                   required: true,
                                                   validate: {
                                                       Validate(value) {
                                                           return (value !== undefined && value?.trim().length !== 0 && !textFieldPattern.test(value));
                                                       }
                                                   },
                                                   onBlur(event: any) {
                                                       if(event.target.value.length === 0) {
                                                           setError('projectSettings.ignoreByCustomField', {type: "required"});
                                                       } else if(textFieldPattern.test(event.target.value)) {
                                                           setError('projectSettings.ignoreByCustomField', {type: "pattern"});
                                                           setValue('projectSettings.ignoreByCustomField', "");
                                                       }
                                                   }
                                               })}
                                               InputLabelProps={{shrink: !!watch('projectSettings.ignoreByCustomField')}}
                                               FormHelperTextProps={{'data-qa': 'ignore-by-custom-field-projects-helperText'}}
                                               helperText={getHelperText(t('ignoreByCustomFieldEnabledProjectsHelperText'), t('ignoreByCustomFieldEnabledProjectsWarningMessage'), t('textFieldValidationMessage'), errors.projectSettings?.ignoreByCustomField)}
                                               error={!!errors.projectSettings?.ignoreByCustomField}/>
                                }</Box>}

                        <Autocomplete id="filter-by-project-status-projects"
                                      data-qa="filter-by-project-status-projects"
                                      onChange={handleOnChangeFilterByProjectStatus}
                                      verticalSpacingSize={3}
                                      verticalSpacingPosition={"top"}
                                      multiple
                                      limitTags={5}
                                      options={values.projectSettings?.filterByProjectStatus}
                                      autoHighlight
                                      value={values.projectSettings?.filterByProjectStatus}
                                      filterOptions={(options, params) => {
                                          const filtered: string[] = [];
                                          if(params.inputValue !== '' && !params.inputValue.includes(' ') && !textFieldPattern.test(params.inputValue)) {
                                              filtered.push(
                                                  params.inputValue.toLowerCase()
                                              );
                                          }
                                          return filtered;
                                      }}
                                      label={t('filterByProjectStatus')}
                                      name="projectSettings.filterByProjectStatus"
                                      customText={{noOptionsText: t('multipleTextFieldValidationMessage')}}
                                      slotProps={{
                                          clearIndicator: {'data-qa': 'filter-by-project-status-clear-indicator'},
                                          popupIndicator: {'data-qa': 'filter-by-project-status-popup-indicator'},
                                          popper: {'data-qa': 'filter-by-project-status-popper'},
                                          paper: {'data-qa': 'filter-by-project-status-paper'}
                                      }}
                                      FormHelperTextProps={{'data-qa': 'filter-by-project-status-helperText'}}
                                      helperText={t('filterByProjectStatusHelperText')}/>

                        <Controller
                            render={(props) => {
                                return (
                                    <Autocomplete id="synchronizeProjectResources"
                                                  data-qa="synchronize-project-resources-projects"
                                                  label={t('synchronizeProjectResources')}
                                                  verticalSpacingSize={3}
                                                  verticalSpacingPosition={"top"}
                                                  options={Object.keys({limitExpensesToResources: t('limitExpensesToResources')})}
                                                  getOptionLabel={(option) => {
                                                      return `${getValueByKey({limitExpensesToResources: t('limitExpensesToResources')}, option)}`;
                                                  }}
                                                  onChange={(_, data) => {
                                                      data = (data === null) ? '' : data;
                                                      return props.field.onChange(data);
                                                  }}
                                                  defaultValue={getDefaultValue(values.projectSettings?.synchronizeProjectResources)}
                                                  slotProps={{
                                                      clearIndicator: {'data-qa': 'synchronize-project-resources-clear-indicator'},
                                                      popupIndicator: {'data-qa': 'synchronize-project-resources-popup-indicator'},
                                                      popper: {'data-qa': 'synchronize-project-resources-popper'},
                                                      paper: {'data-qa': 'synchronize-project-resources-paper'}
                                                  }}
                                                  FormHelperTextProps={{'data-qa': 'synchronize-project-resources-helperText'}}
                                                  helperText={t('synchronizeProjectResourcesHelperText')}/>
                                );
                            }}
                            control={control}
                            name="projectSettings.synchronizeProjectResources"
                        />

                        {syncDepartment && <TextField id="projectDepartment"
                                                      data-qa="department-projects-users"
                                                      inputProps={{"data-qa": "inner-department-projects-users"}}
                                                      verticalSpacingSize={3}
                                                      verticalSpacingPosition={"top"}
                                                      type="text"
                                                      {...register('projectSettings.department',
                                                          {
                                                              validate: {
                                                                  Validate(value) {
                                                                      if(value !== undefined) {
                                                                          return (!textFieldPattern.test(value));
                                                                      }
                                                                  }
                                                              },
                                                              onBlur(event: any) {
                                                                  if(event.target.value.length !== 0 && textFieldPattern.test(event.target.value)) {
                                                                      setValue("projectSettings.department", "");
                                                                  }
                                                              }
                                                          })}
                                                      InputLabelProps={{shrink: !!watch('projectSettings.department')}}
                                                      label={t('department')}
                                                      fullWidth
                                                      error={!!errors.projectSettings?.department}
                                                      FormHelperTextProps={{'data-qa': 'department-projects-users-helperText'}}
                                                      helperText={errors.projectSettings?.department ? t('textFieldValidationMessage') : t('projectDepartmentHelperText')}/>
                        }
                        {syncClass && <TextField id="projectClass"
                                                 data-qa="class-projects-users"
                                                 inputProps={{"data-qa": "inner-class-projects-users"}}
                                                 verticalSpacingSize={3}
                                                 verticalSpacingPosition={"top"}
                                                 type="text"
                                                 {...register('projectSettings.clazz',
                                                     {
                                                         validate: {
                                                             Validate(value) {
                                                                 if(value !== undefined) {
                                                                     return (!textFieldPattern.test(value));
                                                                 }
                                                             }
                                                         },
                                                         onBlur(event: any) {
                                                             if(event.target.value.length !== 0 && textFieldPattern.test(event.target.value)) {
                                                                 setValue("projectSettings.clazz", "");
                                                             }
                                                         }
                                                     })}
                                                 InputLabelProps={{shrink: !!watch('projectSettings.clazz')}}
                                                 label={t('class')}
                                                 fullWidth
                                                 error={!!errors.projectSettings?.clazz}
                                                 FormHelperTextProps={{'data-qa': 'class-projects-users-helperText'}}
                                                 helperText={errors.projectSettings?.clazz ? t('textFieldValidationMessage') : t('projectClassHelperText')}/>
                        }
                        {syncLocation && <TextField id="projectLocation"
                                                    data-qa="location-projects-users"
                                                    inputProps={{"data-qa": "inner-location-projects-users"}}
                                                    verticalSpacingSize={3}
                                                    verticalSpacingPosition={"top"}
                                                    type="text"
                                                    {...register('projectSettings.location',
                                                        {
                                                            validate: {
                                                                Validate(value) {
                                                                    if(value !== undefined) {
                                                                        return (!textFieldPattern.test(value));
                                                                    }
                                                                }
                                                            },
                                                            onBlur(event: any) {
                                                                if(event.target.value.length !== 0 && textFieldPattern.test(event.target.value)) {
                                                                    setValue("projectSettings.location", "");
                                                                }
                                                            }
                                                        })}
                                                    InputLabelProps={{shrink: !!watch('projectSettings.location')}}
                                                    label={t('location')}
                                                    fullWidth
                                                    error={!!errors.projectSettings?.location}
                                                    FormHelperTextProps={{'data-qa': 'location-projects-users-helperText'}}
                                                    helperText={errors.projectSettings?.location ? t('textFieldValidationMessage') : t('projectLocationHelperText')}/>
                        }
                    </form>
                </Box>}
            </Box>
        </ViewContainer>

        <BottomNavBar
            prevDataQA="back-to-users-button-navigation"
            prevText={t('users')}
            prevRoutes={RouteLink.Users}
            nextDataQA="next-to-expenses-button-navigation"
            nextText={t('expenses')}
            nextRoutes={RouteLink.Expenses}/>
    </>
}