import React from 'react'
import {Alert, Box, Button, isNullOrEmpty, Link, Spacer, TextField, Typography} from "@emburse/embark-core";
import {useTranslation} from "react-i18next";
import {AccountCredentialSettings, RouteLink} from "enums";
import {getCustomFieldsMap, getNetSuiteCredentialsFromLocalStorage, lodash} from "utils";
import secureLocalStorage from "react-secure-storage";
import {ConfigurationService} from "services";
import {FieldValues, useForm, useWatch} from 'react-hook-form';
import {ConfigurationContext} from 'providers';
import {BottomNavBar, ViewContainer} from "components";

export const AccountCredentials = () => {
    const {t} = useTranslation();

    const {
        featureFlags,
        setConfiguration,
        setHasError,
        hasError,
        configurationId,
        companyId,
        savedNetSuiteAuthenticateStatus,
        setSavedNetSuiteAuthenticateStatus,
        isAuthenticationStatusSuccess,
        setIsAuthenticationStatusSuccess,
        netsuiteAuthentication,
        setNetsuiteAuthentication,
        setNetSuiteCustomEmployeeFields,
        setNetSuiteCustomVendorFields,
        setNetSuiteCustomExpenseFields
    } = React.useContext(ConfigurationContext);
    const configurationService = new ConfigurationService(companyId);
    const netSuiteCredentialsFromLocalStorage = getNetSuiteCredentialsFromLocalStorage(companyId, configurationId);

    const ACCOUNT_MAX_LENGTH = 60;
    const TOKEN_ID_MAX_LENGTH = 64;
    const TOKEN_SECRET_MAX_LENGTH = 64;
    const CONSUMER_KEY_MAX_LENGTH = 64;
    const CONSUMER_SECRET_MAX_LENGTH = 64;

    const SUCCESSFUL = 'successful';
    const FAILURE = 'failure';

    const {
        register,
        handleSubmit,
        control,
        getValues,
        formState,
        watch,
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            account: netSuiteCredentialsFromLocalStorage.account,
            tokenId: netSuiteCredentialsFromLocalStorage.tokenId,
            tokenSecret: netSuiteCredentialsFromLocalStorage.tokenSecret,
            consumerKey: netSuiteCredentialsFromLocalStorage.consumerKey,
            consumerSecret: netSuiteCredentialsFromLocalStorage.consumerSecret,
        }
    });

    const values = useWatch({control});
    const [isErrorNetSuiteAuthentication, setIsErrorNetSuiteAuthentication] = React.useState<boolean>(netSuiteCredentialsFromLocalStorage.authenticateStatus === FAILURE);
    const [isEditMode, setIsEditMode] = React.useState<boolean>(netSuiteCredentialsFromLocalStorage.authenticateStatus !== SUCCESSFUL);
    const [authenticateStatus, setAuthenticateStatus] = React.useState<string | undefined>(netSuiteCredentialsFromLocalStorage.authenticateStatus);

    React.useEffect(() => {
        if(!netsuiteAuthentication.inProgress) {
            const authenticationStatus = netsuiteAuthentication.result;
            if(!lodash.isNil(authenticationStatus)) {
                setIsErrorNetSuiteAuthentication(!netsuiteAuthentication.result);
                setIsAuthenticationStatusSuccess(netsuiteAuthentication.result as boolean);
                setAuthenticateStatus(netsuiteAuthentication.result ? SUCCESSFUL : FAILURE);
            }
        }
    }, [netsuiteAuthentication]);// eslint-disable-line react-hooks/exhaustive-deps

    const edit = () => {
        setIsEditMode(true)
        setIsErrorNetSuiteAuthentication(false);
        setNetsuiteAuthentication({});
    }

    const authenticate = async() => {
        setIsAuthenticationStatusSuccess(false);
        setNetsuiteAuthentication({inProgress: true});
        setSavedNetSuiteAuthenticateStatus("");
        await configurationService.authenticateNetSuite(values).then(response => {
            if(response) {
                if(featureFlags.customFieldsFeatureEnabled) {
                    configurationService.syncCustomFields().then(response => {

                    setIsErrorNetSuiteAuthentication(false);
                    setIsAuthenticationStatusSuccess(true);
                    setAuthenticateStatus(SUCCESSFUL);
                    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + AccountCredentialSettings.AUTHENTICATION_NETSUITE_STATUS, SUCCESSFUL);

                        if(response != null) {
                            setNetSuiteCustomEmployeeFields(getCustomFieldsMap(response.employeeCustomFields));
                            setNetSuiteCustomVendorFields(getCustomFieldsMap(response.vendorCustomFields));
                            setNetSuiteCustomExpenseFields(getCustomFieldsMap(response.expenseCustomFields));
                        }
                        authenticationEnd(true);
                    });
                } else {
                    setIsErrorNetSuiteAuthentication(false);
                    setIsAuthenticationStatusSuccess(true);
                    setAuthenticateStatus(SUCCESSFUL);
                    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + AccountCredentialSettings.AUTHENTICATION_NETSUITE_STATUS, SUCCESSFUL);
                    authenticationEnd(true);
                }
            } else {
                setIsErrorNetSuiteAuthentication(true);
                setIsAuthenticationStatusSuccess(false);
                secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + AccountCredentialSettings.AUTHENTICATION_NETSUITE_STATUS, FAILURE);
                setAuthenticateStatus(FAILURE);
                authenticationEnd(false);
            }
        });
    };

    const authenticationEnd = (response: boolean) => {
        setConfiguration({
            credential: {
                account: values.account,
                tokenId: values.tokenId,
                tokenSecret: values.tokenSecret,
                consumerKey: values.consumerKey,
                consumerSecret: values.consumerSecret,
                authenticateStatus: response ? SUCCESSFUL : FAILURE
            }
        });
        setIsEditMode(false);
        setNetsuiteAuthentication({inProgress: false, result: response});
    }

    const {errors} = formState;

    function saveInLocalStorage(formData: FieldValues) {
        if(!lodash.isNil(formData.account) && formData.account.toLocaleString().length <= ACCOUNT_MAX_LENGTH) {
            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + AccountCredentialSettings.ACCOUNT, formData.account);
        }
        if(!lodash.isNil(formData.tokenId) && formData.tokenId.toLocaleString().length <= TOKEN_ID_MAX_LENGTH) {
            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + AccountCredentialSettings.TOKEN_ID, formData.tokenId);
        }
        if(!lodash.isNil(formData.tokenSecret) && formData.tokenSecret.toLocaleString().length <= TOKEN_SECRET_MAX_LENGTH) {
            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + AccountCredentialSettings.TOKEN_SECRET, formData.tokenSecret);
        }
        if(!lodash.isNil(formData.consumerKey) && formData.consumerKey.toLocaleString().length <= CONSUMER_KEY_MAX_LENGTH) {
            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + AccountCredentialSettings.CONSUMER_KEY, formData.consumerKey);
        }
        if(!lodash.isNil(formData.consumerSecret) && formData.consumerSecret.toLocaleString().length <= CONSUMER_SECRET_MAX_LENGTH) {
            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + AccountCredentialSettings.CONSUMER_SECRET, formData.consumerSecret);
        }
    }

    const [isFormEmpty, setIsFormEmpty] = React.useState<boolean>();

    React.useEffect(() => {
        if(!netsuiteAuthentication.inProgress) {
            const {account, tokenId, tokenSecret, consumerKey, consumerSecret} = getValues();
            setIsFormEmpty(isNullOrEmpty(account) || isNullOrEmpty(tokenId) || isNullOrEmpty(tokenSecret) || isNullOrEmpty(consumerKey) || isNullOrEmpty(consumerSecret));
        }
    }, [formState]);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if(!netsuiteAuthentication.inProgress) {
            if(formState.isValid) {
                const changeMade = !lodash.isEqual(values, netSuiteCredentialsFromLocalStorage);
                if(changeMade && isEditMode) {
                    setSavedNetSuiteAuthenticateStatus("");
                    setAuthenticateStatus("");
                    setIsAuthenticationStatusSuccess(false);
                    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + AccountCredentialSettings.AUTHENTICATION_NETSUITE_STATUS, "");

                    setConfiguration({
                        credential: {
                            account: values.account,
                            tokenId: values.tokenId,
                            tokenSecret: values.tokenSecret,
                            consumerKey: values.consumerKey,
                            consumerSecret: values.consumerSecret,
                            authenticateStatus: ""
                        }
                    });

                }
                saveInLocalStorage(values);
            }
            setHasError(!formState.isValid);
        }
    }, [values, formState.isValid]);// eslint-disable-line react-hooks/exhaustive-deps

    const getErrorMax = (maxLength: number, fieldName: string) => {
        return {value: maxLength, message: t('accountCredentialsErrorMessage', {fieldName, maxLength})};
    };

    return <>
        <ViewContainer data-qa="account-credentials-page">
            <Typography verticalSpacingSize={1} variant="h4"
                        data-qa="title-account-credentials">{t('accountCredentials')}</Typography>
            <Typography verticalSpacingSize={1}
                        data-qa="sub-title-account-credentials">{t('subTitleNetSuiteCredentials')}</Typography>
            <Typography verticalSpacingSize={6}
                        style={{fontStyle: "italic"}}
                        data-qa="sub-title2-account-credentials">{t('subTitle2NetSuiteCredentials')}
                <Link data-qa="learn-more-credentials-page"
                      target="_blank"
                      href="https://help.certify.com/hc/en-us/articles/26813337243661-Generating-the-NetSuite-Authentication-Token-for-Emburse-NetSuite-Integration">
                    {t('learnMore')}
                </Link>
            </Typography>

            <form onSubmit={handleSubmit(authenticate)} data-qa="account-credentials-form">
                {authenticateStatus === SUCCESSFUL && !isEditMode &&
                    <>
                        {isAuthenticationStatusSuccess && savedNetSuiteAuthenticateStatus !== SUCCESSFUL && !netsuiteAuthentication.inProgress &&
                            <Alert verticalSpacingSize={6}
                                   verticalSpacingPosition="both"
                                   data-qa="success-authentication-message"
                                   severity={'success'}>{t('netSuite.authentication.success')}</Alert>}

                        {savedNetSuiteAuthenticateStatus === SUCCESSFUL && !netsuiteAuthentication.inProgress &&
                            <Alert verticalSpacingSize={6} verticalSpacingPosition="both"
                                   data-qa="authentication-success-saved-message"
                                   severity={'success'}>{t('netSuite.authentication.saved')}</Alert>}

                        <TextField id="accountId"
                                   data-qa="readonly-account-id-credentials-page"
                                   inputProps={{"data-qa": "inner-readonly-account-id-credentials-page"}}
                                   verticalSpacingSize={3}
                                   readOnly
                                   type="text"
                                   {...register('account')}
                                   label={t('accountId')}/>

                        <Box>
                            <Button id="reauthenticate"
                                    isLoading={netsuiteAuthentication.inProgress}
                                    data-qa="reauthenticate-button"
                                    onClick={authenticate}>
                                {t('reauthenticate')}
                            </Button>
                            <Spacer direction="horizontal" size={16} />
                            <Button id="edit-mode"
                                    disabled={netsuiteAuthentication.inProgress}
                                    data-qa="edit-button-credentials-page"
                                    variant="outlined"
                                    onClick={edit}>
                                {t('edit')}
                            </Button>
                        </Box>
                    </>
                }

                {(isEditMode || authenticateStatus !== SUCCESSFUL) && <>

                    {(isErrorNetSuiteAuthentication || savedNetSuiteAuthenticateStatus === FAILURE) && !netsuiteAuthentication.inProgress &&
                        <Alert verticalSpacingSize={6} verticalSpacingPosition="both"
                               data-qa="authentication-failure-message"
                               severity={'error'}>{t('netSuite.authentication.failure')}</Alert>}

                    <TextField id="accountId"
                               data-qa="account-id-credentials-page"
                               inputProps={{"data-qa": "inner-account-id-credentials-page"}}
                               verticalSpacingSize={3}
                               type="text"
                               {...register('account', {maxLength: getErrorMax(ACCOUNT_MAX_LENGTH, t('accountId'))})}
                               InputLabelProps={{shrink: !!watch('account')}}
                               label={t('accountId')}
                               fullWidth
                               readOnly={netsuiteAuthentication.inProgress}
                               error={!!errors?.account}
                               FormHelperTextProps={{'data-qa': 'account-id-helperText'}}
                               helperText={errors?.account?.message as string}/>

                    <TextField id="tokenId"
                               data-qa="token-id-credentials-page"
                               inputProps={{"data-qa": "inner-token-id-credentials-page"}}
                               verticalSpacingSize={3}
                               type="text"
                               {...register('tokenId', {maxLength: getErrorMax(TOKEN_ID_MAX_LENGTH, t('tokenId'))})}
                               InputLabelProps={{shrink: !!watch('tokenId')}}
                               label={t('tokenId')}
                               fullWidth
                               readOnly={netsuiteAuthentication.inProgress}
                               error={!!errors?.tokenId}
                               FormHelperTextProps={{'data-qa': 'token-id-helperText'}}
                               helperText={errors?.tokenId?.message as string}/>

                    <TextField id="tokenSecret"
                               data-qa="token-secret-credentials-page"
                               inputProps={{"data-qa": "inner-token-secret-credentials-page"}}
                               verticalSpacingSize={3}
                               type="text"
                               {...register('tokenSecret', {maxLength: getErrorMax(TOKEN_SECRET_MAX_LENGTH, t('tokenSecret'))})}
                               InputLabelProps={{shrink: !!watch('tokenSecret')}}
                               label={t('tokenSecret')}
                               fullWidth
                               readOnly={netsuiteAuthentication.inProgress}
                               error={!!errors?.tokenSecret}
                               FormHelperTextProps={{'data-qa': 'token-secret-helperText'}}
                               helperText={errors?.tokenSecret?.message as string}/>

                    <TextField id="consumerKey"
                               data-qa="consumer-key-credentials-page"
                               inputProps={{"data-qa": "inner-consumer-key-credentials-page"}}
                               verticalSpacingSize={3}
                               type="text"
                               {...register('consumerKey', {maxLength: getErrorMax(CONSUMER_KEY_MAX_LENGTH, t('consumerKey'))})}
                               InputLabelProps={{shrink: !!watch('consumerKey')}}
                               label={t('consumerKey')}
                               fullWidth
                               readOnly={netsuiteAuthentication.inProgress}
                               error={!!errors?.consumerKey}
                               FormHelperTextProps={{'data-qa': 'consumer-key-helperText'}}
                               helperText={errors?.consumerKey?.message as string}/>
                    <TextField id="consumerSecret"
                               data-qa="consumer-secret-credentials-page"
                               inputProps={{"data-qa": "inner-consumer-secret-credentials-page"}}
                               verticalSpacingSize={3}
                               type="text"
                               {...register('consumerSecret', {maxLength: getErrorMax(CONSUMER_SECRET_MAX_LENGTH, t('consumerSecret'))})}
                               InputLabelProps={{shrink: !!watch('consumerSecret')}}
                               label={t('consumerSecret')}
                               fullWidth
                               readOnly={netsuiteAuthentication.inProgress}
                               error={!!errors?.consumerSecret}
                               FormHelperTextProps={{'data-qa': 'consumer-secret-helperText'}}
                               helperText={errors?.consumerSecret?.message as string}/>

                    <Button id="authenticate"
                            isLoading={netsuiteAuthentication.inProgress}
                            disabled={isFormEmpty || hasError}
                            data-qa="authenticate-button"
                            onClick={authenticate}>
                        {t('authenticate')}
                    </Button>
                </>}
            </form>
        </ViewContainer>

        <BottomNavBar
            nextDataQA="next-to-general-button-navigation"
            nextText={t('general')}
            nextRoutes={RouteLink.General}/>
    </>
}

