import { isNullOrEmpty } from "@emburse/embark-core";
import { FieldError } from "react-hook-form";
import {INetSuiteCustomField} from "models/CustomField";


export const textFieldPattern = /\W/;
export const lodash = require("lodash");

export const getHelperText = (helperText: string, requiredMessageError: string, validationMessageError: string, field?: FieldError) => {
    let helperTextOrErrorMessage = helperText;
    if(field !== undefined) {
        helperTextOrErrorMessage = (field.type === 'required') ? requiredMessageError : validationMessageError;
    }
    return helperTextOrErrorMessage;
}

export const deepCopy = (obj: unknown) => {
    return JSON.parse(JSON.stringify(obj));
};

export const isEmptyObject = (obj: Record<string, unknown>) => {
    return (Object.keys(obj).length === 0 && obj.constructor === Object);
};

export const toBoolean = (prop: unknown, fallback = false) => {
    let boolValue = fallback;
    if(typeof prop === 'boolean') {
        boolValue = prop;
    } else if(typeof prop === 'string') {
        boolValue = (prop === 'true');
    }
    return boolValue;
};

export const formatBytes = (bytes: number, decimals = 2) => {
    if(bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
};

export const scrollToRef = (ref: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
    return ref.current.scrollIntoView({behavior: 'smooth', block: 'start'});
};

export const capitalize = (value: string) => {
    if(value) {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return value;
};

export const toNumber = (prop: unknown, fallback = 0) => {
    let numValue = fallback;
    if(typeof prop === 'string') {
        numValue = Number(prop);
    }
    return isNaN(numValue) ? fallback : numValue;
};

export const excludeStrFromArrayOfStrs = (arr: string[], str: string) => {
    return arr.filter((val) => {
        return val !== str;
    });
};

export const getDefaultValue = (value: any) => {
    return isNullOrEmpty(value) ? undefined : value;
};

export const getValueByKey = (list: any, value: any) => {
    if(value !== null && value !== undefined) {
        return Object.entries(list).find(([key]) => key === value)?.[1] as string;
    } else {
        return '';
    }
};

export const isValuesExistsKeyMap = (values: string[] | undefined, list: Map<string, string>) => {
    if(values === undefined) {
        return false;
    }
    let found = false;
    values.forEach((element: string) => {
        const value = list.get(element) as string;
        if(value === undefined && element !== undefined) {
            found = true;
        }
    });
    return found;
}

export const getCustomFieldsMap = (netSuiteCustomField: INetSuiteCustomField[]) => {
    let customFieldsMap: Map<string, string> = new Map();
    netSuiteCustomField.map(value => (customFieldsMap.set(value.internalId, value.label + " (" + value.internalId + ")")));
    return customFieldsMap;
}

export const getMapUserSupervisorDefaultValue = (value: any) => {
    return (lodash.isNil(value) || value === '') ? 'supervisor' : value;
};