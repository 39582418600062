import React from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { AccountCredentials } from "views/AccountCredentials/AccountCredentials";
import { ConfigurationError } from 'views/Errors/ConfigurationError';
import { ConfigurationLoading } from 'views/App/ConfigurationLoading';
import { Error404 } from 'views/Errors/Error404';
import { RouteLink } from 'enums';
import { General } from "views/General/General";
import { Expenses } from "views/Expense/Expenses";
import { Users } from "views/Users/Users";
import { CustomersAndProjects } from "views/CustomersAndProjects/CustomersAndProjects";
import { FooterBar, Header, NavBar } from "components";
import { EmbarkProvider, ResponsiveContainer } from "@emburse/embark-core";
import { ConfigurationContext } from 'providers/ConfigurationProvider';
import { EmburseLocaleProvider } from "@emburse/embark-locale";
import { i18nInstance } from 'locale/i18n';
import { Review } from "views/Review/Review";


export const App = () => {
    const {designToken, designTokenDarkMode, isDarkMode, locale} = React.useContext(ConfigurationContext);

    const {
        hasConfigurationError,
        finishLoading
    } = React.useContext(ConfigurationContext);

    if(!finishLoading) {
        return (
            <EmburseLocaleProvider value={{i18n: i18nInstance(locale)}}>
                <EmbarkProvider theme={{
                    responsive: {sidebar: true},
                    darkMode: isDarkMode,
                    designToken: designToken,
                    designTokenDarkMode: designTokenDarkMode
                }}>
                    <ConfigurationLoading></ConfigurationLoading>
                </EmbarkProvider>
            </EmburseLocaleProvider>);

    } else if(hasConfigurationError) {
        return (
            <EmburseLocaleProvider value={{i18n: i18nInstance(locale)}}>
                <EmbarkProvider theme={{
                    responsive: {sidebar: true},
                    darkMode: isDarkMode,
                    designToken: designToken,
                    designTokenDarkMode: designTokenDarkMode
                }}>
                    <ConfigurationError></ConfigurationError>
                </EmbarkProvider>
            </EmburseLocaleProvider>);

    } else return (
        <EmburseLocaleProvider value={{i18n: i18nInstance(locale)}}>
            <EmbarkProvider theme={{
                responsive: {sidebar: true},
                darkMode: isDarkMode,
                designToken: designToken,
                designTokenDarkMode: designTokenDarkMode
            }}>
                <Router>
                    <Header/>
                    <NavBar/>
                    <ResponsiveContainer isRoot accountForSidebar permanentSidebar sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        top: 0,
                        paddingTop: '24px',
                        paddingBottom: '24px',
                        whiteSpace: 'pre-line'
                    }}>
                        <Routes>
                            <Route
                                path={RouteLink.Home}
                                element={<AccountCredentials/>}/>
                            <Route
                                path={RouteLink.AccountCredentials}
                                element={<AccountCredentials/>}/>
                            <Route
                                path={RouteLink.General}
                                element={<General/>}/>
                            <Route
                                path={RouteLink.Expenses}
                                element={<Expenses/>}/>
                            <Route
                                path={RouteLink.Users}
                                element={<Users/>}/>
                            <Route
                                path={RouteLink.CustomersAndProjects}
                                element={<CustomersAndProjects/>}/>
                            <Route
                                path={RouteLink.Review}
                                element={<Review/>}/>
                            <Route
                                path="*"
                                element={<Error404/>}/>
                        </Routes>
                    </ResponsiveContainer>
                    <FooterBar/>
                </Router>
            </EmbarkProvider>
        </EmburseLocaleProvider>
        
    );
};
