import secureLocalStorage from "react-secure-storage";
import {
    IConfiguration,
    ICustomers,
    ICustomersAndProjects,
    IExpenses,
    IGeneral,
    IGetConfigurations,
    INetSuiteCredentials,
    IProjects,
    IUsers
} from "models";
import { CustomerAndProjectSettings, ExpenseSettings, GeneralSettings, AccountCredentialSettings, UserSettings } from "enums";
import {getMapUserSupervisorDefaultValue} from "./utils";

export const getDefaultStringValue = (key: string) => {
    return (secureLocalStorage.getItem(key) ?? '') as string;
};

export const getDefaultBooleanValue = (key: string) => {
    return (secureLocalStorage.getItem(key) ?? false) as boolean;
};

export const getDefaultEmptyListValue = (key: string) => {
    return (secureLocalStorage.getItem(key) ?? []) as string[];
};

export const getSyncDepartmentFromLocalStorage = (companyId: string, id: string) => {
    return getDefaultBooleanValue(companyId + "_" + id + "_" + GeneralSettings.SYNC_DEPARTMENT_ENABLED)
};

export const getSyncClassFromLocalStorage = (companyId: string, id: string) => {
    return getDefaultBooleanValue(companyId + "_" + id + "_" + GeneralSettings.SYNC_CLASS_ENABLED)
};

export const getSyncLocationFromLocalStorage = (companyId: string, id: string) => {
    return getDefaultBooleanValue(companyId + "_" + id + "_" + GeneralSettings.SYNC_LOCATION_ENABLED)
};

export const getConfigurationFromLocalStorage = (companyId: string, id: string): IConfiguration => {
    return {
        credential: getNetSuiteCredentialsFromLocalStorage(companyId, id),
        general: getGeneralFromLocalStorage(companyId, id),
        expense: getExpensesFromLocalStorage(companyId, id),
        user: getUsersFromLocalStorage(companyId, id),
        customerAndProject: getCustomersAndProjectsToBeSaved(companyId, id)
    }
};

export const getNetSuiteCredentialsFromLocalStorage = (companyId: string, id: string): INetSuiteCredentials => {
    const account = getDefaultStringValue(companyId + "_" + id + "_" + AccountCredentialSettings.ACCOUNT);
    const tokenId = getDefaultStringValue(companyId + "_" + id + "_" + AccountCredentialSettings.TOKEN_ID);
    const tokenSecret = getDefaultStringValue(companyId + "_" + id + "_" + AccountCredentialSettings.TOKEN_SECRET);
    const consumerKey = getDefaultStringValue(companyId + "_" + id + "_" + AccountCredentialSettings.CONSUMER_KEY);
    const consumerSecret = getDefaultStringValue(companyId + "_" + id + "_" + AccountCredentialSettings.CONSUMER_SECRET);
    const authenticateStatus = getDefaultStringValue(companyId + "_" + id + "_" + AccountCredentialSettings.AUTHENTICATION_NETSUITE_STATUS);

    return {
        account: account,
        tokenId: tokenId,
        tokenSecret: tokenSecret,
        consumerKey: consumerKey,
        consumerSecret: consumerSecret,
        authenticateStatus: authenticateStatus
    };
};

export const getGeneralFromLocalStorage = (companyId: string, id: string): IGeneral => {
    const subsidiaries = getDefaultBooleanValue(companyId + "_" + id + "_" + GeneralSettings.SUBSIDIARIES_ENABLED);
    const syncDepartment = getDefaultBooleanValue(companyId + "_" + id + "_" + GeneralSettings.SYNC_DEPARTMENT_ENABLED);
    const syncClass = getDefaultBooleanValue(companyId + "_" + id + "_" + GeneralSettings.SYNC_CLASS_ENABLED);
    const syncLocation = getDefaultBooleanValue(companyId + "_" + id + "_" + GeneralSettings.SYNC_LOCATION_ENABLED);

    return {
        subsidiariesEnabled: subsidiaries,
        syncDepartmentEnabled: syncDepartment,
        syncClassEnabled: syncClass,
        syncLocationEnabled: syncLocation
    };
};

export const getExpensesFromLocalStorage = (companyId: string, id: string): IExpenses => {
    const importExpenseCategoriesEnabled = getDefaultBooleanValue(companyId + "_" + id + "_" + ExpenseSettings.EXPENSE_IMPORT_CATEGORIES_ENABLED);
    const synchronizeNetsuiteExpenseCustomFieldsEnabled = getDefaultBooleanValue(companyId + "_" + id + "_" + ExpenseSettings.SYNCHRONIZE_NETSUITE_EXPENSE_CUSTOM_FIELDS_ENABLED);
    const netsuiteCustomFieldsToImport = getDefaultEmptyListValue(companyId + "_" + id + "_" + ExpenseSettings.NETSUITE_CUSTOM_FIELDS_TO_IMPORT)
    const multipleCurrenciesEnabled = getDefaultBooleanValue(companyId + "_" + id + "_" + ExpenseSettings.MULTIPLE_CURRENCIES_ENABLED);
    const netsuiteSuiteTaxesEnabled = getDefaultBooleanValue(companyId + "_" + id + "_" + ExpenseSettings.NETSUITE_SUITE_TAXES_ENABLED);
    const netsuiteTaxableRegions = getDefaultEmptyListValue(companyId + "_" + id + "_" + ExpenseSettings.NETSUITE_TAXABLE_REGIONS)
    const overrideForeignAmountAndExchangeRateEnabled = getDefaultBooleanValue(companyId + "_" + id + "_" + ExpenseSettings.OVERRIDE_FOREIGN_AMOUNT_AND_EXCHANGE_RATE_ENABLED);

    const exportExpenseReportsEnabled = getDefaultBooleanValue(companyId + "_" + id + "_" + ExpenseSettings.EXPORT_EXPENSE_REPORT_ENABLED);
    const expenseReportFormId = getDefaultStringValue(companyId + "_" + id + "_" + ExpenseSettings.EXPENSE_REPORT_FORM_ID);
    const reportNumberPrefix = getDefaultStringValue(companyId + "_" + id + "_" + ExpenseSettings.REPORT_NUMBER_PREFIX);
    const exportExpenseReportAsCompleteEnabled = getDefaultBooleanValue(companyId + "_" + id + "_" + ExpenseSettings.EXPORT_EXPENSE_REPORT_AS_COMPLETE_ENABLED);
    const setSupervisorApprovalEnabled = getDefaultBooleanValue(companyId + "_" + id + "_" + ExpenseSettings.SET_SUPERVISOR_APPROVAL_ENABLED);
    const setAccountingApprovalEnabled = getDefaultBooleanValue(companyId + "_" + id + "_" + ExpenseSettings.SET_ACCOUNTING_APPROVAL_ENABLED);
    const payableAccount = getDefaultStringValue(companyId + "_" + id + "_" + ExpenseSettings.PAYABLE_ACCOUNT);

    const exportCompanyPaidExpensesEnabled = getDefaultBooleanValue(companyId + "_" + id + "_" + ExpenseSettings.EXPORT_COMPANY_PAID_EXPENSES_ENABLED);
    const expenseReportIncludingCorporateCardsEnabled = getDefaultBooleanValue(companyId + "_" + id + "_" + ExpenseSettings.EXPENSE_REPORT_INCLUDING_CORPORATE_CARDS_ENABLED);
    const corporateCardAccount = getDefaultStringValue(companyId + "_" + id + "_" + ExpenseSettings.CORPORATE_CARD_ACCOUNT);

    const exportAsVendorBillsEnabled = getDefaultBooleanValue(companyId + "_" + id + "_" + ExpenseSettings.EXPORT_AS_VENDOR_BILLS_ENABLED);
    const vendorBillFormId = getDefaultStringValue(companyId + "_" + id + "_" + ExpenseSettings.VENDOR_BILL_FORM_ID);
    const vendorCreditFormId = getDefaultStringValue(companyId + "_" + id + "_" + ExpenseSettings.VENDOR_CREDIT_FORM_ID);
    const vendorBillApprovalStatus = getDefaultStringValue(companyId + "_" + id + "_" + ExpenseSettings.VENDOR_BILL_APPROVAL_STATUS);
    const vendorBillCreditCardsEnabled = getDefaultBooleanValue(companyId + "_" + id + "_" + ExpenseSettings.VENDOR_BILL_CREDIT_CARDS_ENABLED);
    const expenseReceiptsEnabled = getDefaultBooleanValue(companyId + "_" + id + "_" + ExpenseSettings.EXPORT_EXPENSE_RECEIPTS_ENABLED);
    const receiptsFolderId = getDefaultStringValue(companyId + "_" + id + "_" + ExpenseSettings.EXPORT_RECEIPTS_FOLDER_ID);

    return {
        importEnabled: true,
        exportEnabled: exportExpenseReportsEnabled || exportAsVendorBillsEnabled,
        importExpenseSettings: {
            importExpenseCategoriesEnabled: importExpenseCategoriesEnabled,
            synchronizeNetsuiteExpenseCustomFieldsEnabled: synchronizeNetsuiteExpenseCustomFieldsEnabled,
            netsuiteCustomFieldsToImport: netsuiteCustomFieldsToImport,
            multipleCurrenciesEnabled: multipleCurrenciesEnabled,
            netsuiteSuiteTaxesEnabled: netsuiteSuiteTaxesEnabled,
            netsuiteTaxableRegions: netsuiteTaxableRegions,
            overrideForeignAmountAndExchangeRateEnabled: overrideForeignAmountAndExchangeRateEnabled
        },
        exportExpenseSettings: {
            exportExpenseReportsEnabled: exportExpenseReportsEnabled,
            expenseReportFormId: expenseReportFormId,
            reportNumberPrefix: reportNumberPrefix,
            exportExpenseReportAsCompleteEnabled: exportExpenseReportAsCompleteEnabled,
            settSupervisorApprovalEnabled: setSupervisorApprovalEnabled,
            settAccountingApprovalEnabled: setAccountingApprovalEnabled,
            payableAccount: payableAccount,

            exportCompanyPaidExpensesEnabled: exportCompanyPaidExpensesEnabled,
            expenseReportIncludingCorporateCardsEnabled: expenseReportIncludingCorporateCardsEnabled,
            corporateCardAccount: corporateCardAccount,

            exportAsVendorBillsEnabled: exportAsVendorBillsEnabled,
            vendorBillFormId: vendorBillFormId,
            vendorCreditFormId: vendorCreditFormId,
            vendorBillApprovalStatus: vendorBillApprovalStatus,
            vendorBillCreditCardsEnabled: vendorBillCreditCardsEnabled,
            expenseReceiptsEnabled: expenseReceiptsEnabled,
            receiptsFolderId: receiptsFolderId
        }
    };
};

export const getUsersFromLocalStorage = (companyId: string, id: string): IUsers => {
    const syncEmployees = getDefaultBooleanValue(companyId + "_" + id + "_" + UserSettings.SYNC_EMPLOYEES);
    const syncVendors = getDefaultBooleanValue(companyId + "_" + id + "_" + UserSettings.SYNC_VENDORS);
    const netSuiteEmployees = {
        filterEmployeesByType: getDefaultEmptyListValue(companyId + "_" + id + "_" + UserSettings.EMPLOYEES_FILTER_EMPLOYEES_BY_TYPE),
        filterEmployeesByCustomFieldId: getDefaultStringValue(companyId + "_" + id + "_" + UserSettings.EMPLOYEES_FILTER_EMPLOYEES_BY_CUSTOM_FIELD_ID),
        mapUserIDs: getDefaultStringValue(companyId + "_" + id + "_" + UserSettings.EMPLOYEES_MAP_USER_IDS),
        mapUserSupervisor: getMapUserSupervisorDefaultValue(getDefaultStringValue(companyId + "_" + id + "_" + UserSettings.EMPLOYEES_MAP_USER_SUPERVISOR)),
        useCustomFieldForUserRole: getDefaultStringValue(companyId + "_" + id + "_" + UserSettings.EMPLOYEES_USE_NETSUITE_CUSTOM_FIELD_FOR_USER_ROLE),
        synchronizeNetsuiteEmployeeCustomFields: getDefaultBooleanValue(companyId + "_" + id + "_" + UserSettings.EMPLOYEES_SYNCHRONIZE_NETSUITE_EMPLOYEE_CUSTOM_FIELDS),
        netsuiteCustomFieldsToImport: getDefaultEmptyListValue(companyId + "_" + id + "_" + UserSettings.EMPLOYEES_NETSUITE_CUSTOM_FIELDS_TO_IMPORT)
    };
    const netSuiteVendors = {
        filterVendorByCustomFieldId: getDefaultStringValue(companyId + "_" + id + "_" + UserSettings.VENDORS_FILTER_VENDOR_BY_CUSTOM_FIELD_ID),
        mapUserIDs: getDefaultStringValue(companyId + "_" + id + "_" + UserSettings.VENDORS_MAP_USER_IDS),
        mapUserSupervisor: getDefaultBooleanValue(companyId + "_" + id + "_" + UserSettings.VENDORS_MAP_USER_SUPERVISOR),
        employee: getDefaultStringValue(companyId + "_" + id + "_" + UserSettings.VENDORS_NETSUITE_EMPLOYEE),
        userVendorNumber: getDefaultStringValue(companyId + "_" + id + "_" + UserSettings.VENDORS_NETSUITE_USER_VENDOR_NUMBER),
        useCustomFieldForUserRole: getDefaultStringValue(companyId + "_" + id + "_" + UserSettings.VENDORS_USE_NETSUITE_CUSTOM_FIELD_FOR_USER_ROLE),
        department: getDefaultStringValue(companyId + "_" + id + "_" + UserSettings.VENDORS_DEPARTMENT),
        clazz: getDefaultStringValue(companyId + "_" + id + "_" + UserSettings.VENDORS_CLASS),
        location: getDefaultStringValue(companyId + "_" + id + "_" + UserSettings.VENDORS_LOCATION),
        synchronizeNetsuiteVendorCustomFields: getDefaultBooleanValue(companyId + "_" + id + "_" + UserSettings.VENDORS_SYNCHRONIZE_NETSUITE_Vendor_CUSTOM_FIELDS),
        netsuiteCustomFieldsToImport: getDefaultEmptyListValue(companyId + "_" + id + "_" + UserSettings.VENDORS_NETSUITE_CUSTOM_FIELDS_TO_IMPORT)
    };

    return {
        syncEmployees: syncEmployees,
        syncVendors: syncVendors,
        employee: netSuiteEmployees,
        vendor: netSuiteVendors
    };
};

export const getCustomersAndProjectsFromLocalStorage = (companyId: string, id: string): ICustomersAndProjects => {
    const syncCustomers = getDefaultBooleanValue(companyId + "_" + id + "_" + CustomerAndProjectSettings.SYNC_CUSTOMERS);
    const syncProjects = getDefaultBooleanValue(companyId + "_" + id + "_" + CustomerAndProjectSettings.SYNC_PROJECTS);

    const customerSettings: ICustomers = {
        useCustomFieldToFilterCustomersEnabled: getDefaultBooleanValue(companyId + "_" + id + "_" + CustomerAndProjectSettings.CUSTOMERS_USE_CUSTOM_FIELD_TO_FILTER_ENABLED),
        filterByCustomFieldEnabled: getDefaultBooleanValue(companyId + "_" + id + "_" + CustomerAndProjectSettings.CUSTOMERS_FILTER_BY_CUSTOM_FIELD_ENABLED),
        ignoreByCustomFieldEnabled: getDefaultBooleanValue(companyId + "_" + id + "_" + CustomerAndProjectSettings.CUSTOMERS_IGNORE_BY_CUSTOM_FIELD_ENABLED),
        filterByCustomField: getDefaultStringValue(companyId + "_" + id + "_" + CustomerAndProjectSettings.CUSTOMERS_FILTER_BY_CUSTOM_FIELD),
        ignoreByCustomField: getDefaultStringValue(companyId + "_" + id + "_" + CustomerAndProjectSettings.CUSTOMERS_IGNORE_BY_CUSTOM_FIELD),
        filterByCustomerStatus: getDefaultEmptyListValue(companyId + "_" + id + "_" + CustomerAndProjectSettings.CUSTOMERS_FILTER_BY_CUSTOMER_STATUS)
    };

    const projectSettings: IProjects = {
        useCustomFieldToFilterProjectsEnabled: getDefaultBooleanValue(companyId + "_" + id + "_" + CustomerAndProjectSettings.PROJECTS_USE_CUSTOM_FIELD_TO_FILTER_ENABLED),
        filterByCustomFieldEnabled: getDefaultBooleanValue(companyId + "_" + id + "_" + CustomerAndProjectSettings.PROJECTS_FILTER_BY_CUSTOM_FIELD_ENABLED),
        ignoreByCustomFieldEnabled: getDefaultBooleanValue(companyId + "_" + id + "_" + CustomerAndProjectSettings.PROJECTS_IGNORE_BY_CUSTOM_FIELD_ENABLED),
        filterByCustomField: getDefaultStringValue(companyId + "_" + id + "_" + CustomerAndProjectSettings.PROJECTS_FILTER_BY_CUSTOM_FIELD),
        ignoreByCustomField: getDefaultStringValue(companyId + "_" + id + "_" + CustomerAndProjectSettings.PROJECTS_IGNORE_BY_CUSTOM_FIELD),
        filterByProjectStatus: getDefaultEmptyListValue(companyId + "_" + id + "_" + CustomerAndProjectSettings.PROJECTS_FILTER_BY_PROJECT_STATUS),
        synchronizeProjectResources: getDefaultStringValue(companyId + "_" + id + "_" + CustomerAndProjectSettings.PROJECTS_SYNCHRONIZE_PROJECT_RESOURCES),
        department: getDefaultStringValue(companyId + "_" + id + "_" + CustomerAndProjectSettings.PROJECTS_DEPARTMENT),
        clazz: getDefaultStringValue(companyId + "_" + id + "_" + CustomerAndProjectSettings.PROJECTS_CLASS),
        location: getDefaultStringValue(companyId + "_" + id + "_" + CustomerAndProjectSettings.PROJECTS_LOCATION)

    };

    return {
        syncCustomersEnabled: syncCustomers,
        syncProjectsEnabled: syncProjects,
        customerSettings: customerSettings,
        projectSettings: projectSettings
    }
}


export const getCustomersAndProjectsToBeSaved = (companyId: string, configurationId: string): ICustomersAndProjects => {
    let customersAndProjects: ICustomersAndProjects = getCustomersAndProjectsFromLocalStorage(companyId, configurationId);
    let customerSettings = customersAndProjects.customerSettings;
    let projectSettings = customersAndProjects.projectSettings;

    if(customerSettings && !customerSettings.filterByCustomFieldEnabled) {
        customerSettings.filterByCustomField = '';
    }

    if(customerSettings && !customerSettings.ignoreByCustomFieldEnabled) {
        customerSettings.ignoreByCustomField = '';
    }

    if(projectSettings && !projectSettings.filterByCustomFieldEnabled) {
        projectSettings.filterByCustomField = '';
    }

    if(projectSettings && !projectSettings.ignoreByCustomFieldEnabled) {
        projectSettings.ignoreByCustomField = '';
    }

    return {
        syncCustomersEnabled: customersAndProjects.syncCustomersEnabled,
        syncProjectsEnabled: customersAndProjects.syncProjectsEnabled,
        customerSettings: customerSettings,
        projectSettings: projectSettings
    }
};

export const saveInLocalStorage = (data: IGetConfigurations) => {
    const configurationId = data.id;
    const companyId = data.companyId;
    secureLocalStorage.clear();

    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + AccountCredentialSettings.ACCOUNT, data.credential?.account as string);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + AccountCredentialSettings.TOKEN_ID, data.credential?.tokenId as string);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + AccountCredentialSettings.TOKEN_SECRET, data.credential?.tokenSecret as string);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + AccountCredentialSettings.CONSUMER_KEY, data.credential?.consumerKey as string);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + AccountCredentialSettings.CONSUMER_SECRET, data.credential?.consumerSecret as string);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + AccountCredentialSettings.AUTHENTICATION_NETSUITE_STATUS, data.credential?.authenticateStatus as string);

    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + GeneralSettings.EXPENSE_IMPORT, true);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + GeneralSettings.EXPENSE_EXPORT, true);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + GeneralSettings.SUBSIDIARIES_ENABLED, data.general?.subsidiariesEnabled as boolean);

    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + GeneralSettings.SYNC_DEPARTMENT_ENABLED, data.general?.syncDepartmentEnabled as boolean);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + GeneralSettings.SYNC_CLASS_ENABLED, data.general?.syncClassEnabled as boolean);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + GeneralSettings.SYNC_LOCATION_ENABLED, data.general?.syncLocationEnabled as boolean);

    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.EXPENSE_IMPORT_CATEGORIES_ENABLED, data.expense?.importExpenseSettings?.importExpenseCategoriesEnabled as boolean);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.SYNCHRONIZE_NETSUITE_EXPENSE_CUSTOM_FIELDS_ENABLED, data.expense?.importExpenseSettings?.synchronizeNetsuiteExpenseCustomFieldsEnabled as boolean);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.NETSUITE_CUSTOM_FIELDS_TO_IMPORT, data.expense?.importExpenseSettings?.netsuiteCustomFieldsToImport as string[]);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.MULTIPLE_CURRENCIES_ENABLED, data.expense?.importExpenseSettings?.multipleCurrenciesEnabled as boolean);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.NETSUITE_SUITE_TAXES_ENABLED, data.expense?.importExpenseSettings?.netsuiteSuiteTaxesEnabled as boolean);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.NETSUITE_TAXABLE_REGIONS, data.expense?.importExpenseSettings?.netsuiteTaxableRegions as string[]);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.OVERRIDE_FOREIGN_AMOUNT_AND_EXCHANGE_RATE_ENABLED, data.expense?.importExpenseSettings?.overrideForeignAmountAndExchangeRateEnabled as boolean);

    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.EXPORT_EXPENSE_REPORT_ENABLED, data.expense?.exportExpenseSettings?.exportExpenseReportsEnabled as boolean);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.EXPENSE_REPORT_FORM_ID, data.expense?.exportExpenseSettings?.expenseReportFormId as string);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.REPORT_NUMBER_PREFIX, data.expense?.exportExpenseSettings?.reportNumberPrefix as string);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.EXPORT_EXPENSE_REPORT_AS_COMPLETE_ENABLED, data.expense?.exportExpenseSettings?.exportExpenseReportAsCompleteEnabled as boolean);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.SET_SUPERVISOR_APPROVAL_ENABLED, data.expense?.exportExpenseSettings?.settSupervisorApprovalEnabled as boolean);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.SET_ACCOUNTING_APPROVAL_ENABLED, data.expense?.exportExpenseSettings?.settAccountingApprovalEnabled as boolean);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.PAYABLE_ACCOUNT, data.expense?.exportExpenseSettings?.payableAccount as string);

    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.EXPORT_COMPANY_PAID_EXPENSES_ENABLED, data.expense?.exportExpenseSettings?.exportCompanyPaidExpensesEnabled as boolean);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.EXPENSE_REPORT_INCLUDING_CORPORATE_CARDS_ENABLED, data.expense?.exportExpenseSettings?.expenseReportIncludingCorporateCardsEnabled as boolean);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.CORPORATE_CARD_ACCOUNT, data.expense?.exportExpenseSettings?.corporateCardAccount as string);

    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.EXPORT_AS_VENDOR_BILLS_ENABLED, data.expense?.exportExpenseSettings?.exportAsVendorBillsEnabled as boolean);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.VENDOR_BILL_FORM_ID, data.expense?.exportExpenseSettings?.vendorBillFormId as string);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.VENDOR_CREDIT_FORM_ID, data.expense?.exportExpenseSettings?.vendorCreditFormId as string);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.VENDOR_BILL_APPROVAL_STATUS, data.expense?.exportExpenseSettings?.vendorBillApprovalStatus as string);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.VENDOR_BILL_CREDIT_CARDS_ENABLED, data.expense?.exportExpenseSettings?.vendorBillCreditCardsEnabled as boolean);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.EXPORT_EXPENSE_RECEIPTS_ENABLED, data.expense?.exportExpenseSettings?.expenseReceiptsEnabled as boolean);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.EXPORT_RECEIPTS_FOLDER_ID, data.expense?.exportExpenseSettings?.receiptsFolderId as string);

    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.SYNC_EMPLOYEES, data.user?.syncEmployees as boolean);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.SYNC_VENDORS, data.user?.syncVendors as boolean);

    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.EMPLOYEES_FILTER_EMPLOYEES_BY_TYPE, data.user?.employee?.filterEmployeesByType as string[]);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.EMPLOYEES_FILTER_EMPLOYEES_BY_CUSTOM_FIELD_ID, data.user?.employee?.filterEmployeesByCustomFieldId as string);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.EMPLOYEES_MAP_USER_IDS, data.user?.employee?.mapUserIDs as string);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.EMPLOYEES_MAP_USER_SUPERVISOR, data.user?.employee?.mapUserSupervisor as string);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.EMPLOYEES_USE_NETSUITE_CUSTOM_FIELD_FOR_USER_ROLE, data.user?.employee?.useCustomFieldForUserRole as string);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.EMPLOYEES_SYNCHRONIZE_NETSUITE_EMPLOYEE_CUSTOM_FIELDS, data.user?.employee?.synchronizeNetsuiteEmployeeCustomFields as boolean);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.EMPLOYEES_NETSUITE_CUSTOM_FIELDS_TO_IMPORT, data.user?.employee?.netsuiteCustomFieldsToImport as string[]);

    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.VENDORS_FILTER_VENDOR_BY_CUSTOM_FIELD_ID, data.user?.vendor?.filterVendorByCustomFieldId as string);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.VENDORS_MAP_USER_IDS, data.user?.vendor?.mapUserIDs as string);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.VENDORS_MAP_USER_SUPERVISOR, data.user?.vendor?.mapUserSupervisor as boolean);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.VENDORS_NETSUITE_EMPLOYEE, data.user?.vendor?.employee as string);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.VENDORS_NETSUITE_USER_VENDOR_NUMBER, data.user?.vendor?.userVendorNumber as string);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.VENDORS_USE_NETSUITE_CUSTOM_FIELD_FOR_USER_ROLE, data.user?.vendor?.useCustomFieldForUserRole as string);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.VENDORS_DEPARTMENT, data.user?.vendor?.department as string);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.VENDORS_CLASS, data.user?.vendor?.clazz as string);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.VENDORS_LOCATION, data.user?.vendor?.location as string);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.VENDORS_SYNCHRONIZE_NETSUITE_Vendor_CUSTOM_FIELDS, data.user?.vendor?.synchronizeNetsuiteVendorCustomFields as boolean);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.VENDORS_NETSUITE_CUSTOM_FIELDS_TO_IMPORT, data.user?.vendor?.netsuiteCustomFieldsToImport as string[]);

    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.SYNC_CUSTOMERS, data.customerAndProject?.syncCustomersEnabled as boolean);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.SYNC_PROJECTS, data.customerAndProject?.syncProjectsEnabled as boolean);

    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.CUSTOMERS_USE_CUSTOM_FIELD_TO_FILTER_ENABLED, data.customerAndProject?.customerSettings?.useCustomFieldToFilterCustomersEnabled as boolean);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.CUSTOMERS_FILTER_BY_CUSTOM_FIELD_ENABLED, data.customerAndProject?.customerSettings?.filterByCustomFieldEnabled as boolean);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.CUSTOMERS_IGNORE_BY_CUSTOM_FIELD_ENABLED, data.customerAndProject?.customerSettings?.ignoreByCustomFieldEnabled as boolean);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.CUSTOMERS_FILTER_BY_CUSTOM_FIELD, data.customerAndProject?.customerSettings?.filterByCustomField as string);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.CUSTOMERS_IGNORE_BY_CUSTOM_FIELD, data.customerAndProject?.customerSettings?.ignoreByCustomField as string);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.CUSTOMERS_FILTER_BY_CUSTOMER_STATUS, data.customerAndProject?.customerSettings?.filterByCustomerStatus as string[]);

    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.PROJECTS_USE_CUSTOM_FIELD_TO_FILTER_ENABLED, data.customerAndProject?.projectSettings?.useCustomFieldToFilterProjectsEnabled as boolean);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.PROJECTS_FILTER_BY_CUSTOM_FIELD_ENABLED, data.customerAndProject?.projectSettings?.filterByCustomFieldEnabled as boolean);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.PROJECTS_IGNORE_BY_CUSTOM_FIELD_ENABLED, data.customerAndProject?.projectSettings?.ignoreByCustomFieldEnabled as boolean);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.PROJECTS_FILTER_BY_CUSTOM_FIELD, data.customerAndProject?.projectSettings?.filterByCustomField as string);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.PROJECTS_IGNORE_BY_CUSTOM_FIELD, data.customerAndProject?.projectSettings?.ignoreByCustomField as string);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.PROJECTS_FILTER_BY_PROJECT_STATUS, data.customerAndProject?.projectSettings?.filterByProjectStatus as string[]);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.PROJECTS_SYNCHRONIZE_PROJECT_RESOURCES, data.customerAndProject?.projectSettings?.synchronizeProjectResources as string);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.PROJECTS_DEPARTMENT, data.customerAndProject?.projectSettings?.department as string);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.PROJECTS_CLASS, data.customerAndProject?.projectSettings?.clazz as string);
    secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.PROJECTS_LOCATION, data.customerAndProject?.projectSettings?.location as string);
};
