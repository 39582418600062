export enum CustomerAndProjectSettings {
    SYNC_CUSTOMERS = 'customerAndProject.syncCustomers',
    SYNC_PROJECTS = 'customerAndProject.syncProjects',
    CUSTOMERS_USE_CUSTOM_FIELD_TO_FILTER_ENABLED='customerAndProject.customers.useCustomFieldToFilterCustomers',
    CUSTOMERS_FILTER_BY_CUSTOM_FIELD_ENABLED = 'customerAndProject.customers.filterByCustomFieldEnabled',
    CUSTOMERS_IGNORE_BY_CUSTOM_FIELD_ENABLED = 'customerAndProject.customers.ignoreByCustomFieldEnabled',
    CUSTOMERS_FILTER_BY_CUSTOM_FIELD = 'customerAndProject.customers.filterByCustomField',
    CUSTOMERS_IGNORE_BY_CUSTOM_FIELD = 'customerAndProject.customers.ignoreByCustomField',
    CUSTOMERS_FILTER_BY_CUSTOMER_STATUS = 'customerAndProject.customers.filterByCustomerStatus',

    PROJECTS_USE_CUSTOM_FIELD_TO_FILTER_ENABLED='customerAndProject.projects.useCustomFieldToFilterProjects',
    PROJECTS_FILTER_BY_CUSTOM_FIELD_ENABLED = 'customerAndProject.projects.filterByCustomFieldEnabled',
    PROJECTS_IGNORE_BY_CUSTOM_FIELD_ENABLED = 'customerAndProject.projects.ignoreByCustomFieldEnabled',
    PROJECTS_FILTER_BY_CUSTOM_FIELD = 'customerAndProject.projects.filterByCustomField',
    PROJECTS_IGNORE_BY_CUSTOM_FIELD = 'customerAndProject.projects.ignoreByCustomField',
    PROJECTS_FILTER_BY_PROJECT_STATUS = 'customerAndProject.projects.filterByProjectStatus',
    PROJECTS_SYNCHRONIZE_PROJECT_RESOURCES = 'customerAndProject.projects.synchronizeProjectResources',
    PROJECTS_DEPARTMENT = 'customerAndProject.projects.department',
    PROJECTS_CLASS = 'customerAndProject.projects.class',
    PROJECTS_LOCATION = 'customerAndProject.projects.location'

}