import {Box, Typography} from '@emburse/embark-core';
import React from 'react';

export interface CheckBoxTypographyProps {
    dataQA?: string;
    value?: string;
}

export const CheckBoxTypography = (props: CheckBoxTypographyProps) => {
    return (
        <Box className="checkboxLeftMargin">
            <Typography variant="body2" data-qa={props.dataQA}>{props.value}</Typography>
        </Box>
    );
};

