import {Box, Typography} from '@emburse/embark-core';
import React from 'react';

export interface SwitchTypographyProps {
    dataQA?: string;
    value?: string;
}

export const SwitchTypography = (props: SwitchTypographyProps) => {
    return (
        <Box className="switchLeftMargin">
            <Typography variant="body1" data-qa={props.dataQA}>{props.value}</Typography>
        </Box>
    );
};

