import { ResponsiveContainer } from '@emburse/embark-core';
import React from 'react';
import { ConfigurationContext } from 'providers';

export interface ViewContainerProps {
    children?: React.ReactNode;
}

export const ViewContainer = ({children, ...props}: ViewContainerProps) => {
    const {isInsideEmbark} = React.useContext(ConfigurationContext);
    const offSetHeight = isInsideEmbark ? 37 : 100;
    return (
        <ResponsiveContainer align='left' size='fullWidth' sx={[!isInsideEmbark && { paddingTop: '61px'}, { paddingBottom: '24px', minHeight: `calc(100% - ${offSetHeight}px)`}]} {...props}>
            {children}
        </ResponsiveContainer>
    );
};

