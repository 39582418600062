import { Alert, Box, Button, ButtonContainer, ModalFooter, ModalHeader, Snackbar } from "@emburse/embark-core";
import { IFrameModalMessageIds } from "@emburse/embark-complex";
import React from "react";
import { useTranslation } from "react-i18next";
import './FooterBar.scss';
import { ConfigurationService, ConfigurationWithTokenService} from "services";
import { ConfigurationContext } from "providers";
import { getConfigurationFromLocalStorage, lodash} from "utils";
import { ConfirmationModal } from "components/ConfirmationModal/ConfirmationModal";
import {RouteLink} from "enums";
import {useNavigate} from "react-router-dom";

export const FooterBar = () => {
    const {t} = useTranslation();
    const {
        configurationId,
        companyId,
        token,
        hasError,
        isInsideEmbark,
        successfulSyncConfiguration,
        setSuccessfulSyncConfiguration,
        initialConfiguration,
        updatedConfiguration
    } = React.useContext(ConfigurationContext);

    const navigate = useNavigate();
    const configurationWithTokenService = new ConfigurationWithTokenService(token);
    const configurationService = new ConfigurationService(companyId);
    const [open, setOpen] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>(t('savingConfigurationErrorMessage'));
    const [reviewModalOpened, setReviewModalOpened] = React.useState(false);
    const [cancelModalOpened, setCancelModalOpened] = React.useState(false);
    const [showFooterButtons, setShowFooterButtons] = React.useState(true);

    const cancel = () => {
        const changeMade = !lodash.isEqual(initialConfiguration, localStorageConfiguration);
        if(changeMade) {
            setCancelModalOpened(true);
            setShowFooterButtons(false);
            if(isInsideEmbark) {
                window.parent.postMessage({message: IFrameModalMessageIds.headerShowClose, value: false}, '*');
            }
        } else {
            closeModal();
        }
    };

    const closeModal = () => {
        if(isInsideEmbark) {
            window.parent.postMessage({
                message: IFrameModalMessageIds.modalCallback,
                value: {action: IFrameModalMessageIds.modalCallBack_closeModal}
            }, '*');
        }
    };

    const localStorageConfiguration = getConfigurationFromLocalStorage(companyId, configurationId);

    const saveAndClose = () => {
        const changeMade = !lodash.isEqual(updatedConfiguration, localStorageConfiguration);
        if(changeMade) {
            setReviewModalOpened(true);
            setShowFooterButtons(false);
            if(isInsideEmbark) {
                window.parent.postMessage({message: IFrameModalMessageIds.headerShowClose, value: false}, '*');
            }
        } else {
            saveSettings(successfulSyncConfiguration);
        }
    };

    const saveSettings = (successfulSyncConfiguration: boolean) => {
        configurationWithTokenService.saveConfigurations(localStorageConfiguration, configurationId).then(response => {
            if(response.success) {
                saveConfigurationStatus(successfulSyncConfiguration);
            } else {
                setOpen(true);
                if(response.expiredToken) {
                    setErrorMessage(t('tokenExpiredError'))
                }
            }
        });
        // Use this line if you want to return some kind of response to the BU
        // window.parent.postMessage({message: IFrameModalMessageIds.modalCallback, value: { action: IFrameModalMessageIds.modalCallBack_final, result: `configuration saved for ${id}`}}, '*');
    };

    const saveConfigurationStatus = (successfulSyncConfiguration:boolean) => {
        configurationService.updateConfigurationStatus(successfulSyncConfiguration, configurationId).then(response => {
            if(response) {
                closeModal();
            } else {
                setOpen(true);
            }
        });
    };

    const refs: any = {
        'cancel': React.useRef(null),
        'saveAndClose': React.useRef(null)
    };

    React.useEffect(() => {
        window.addEventListener('message', handleIFrameModalMessage);

        return () => {
            window.removeEventListener('message', handleIFrameModalMessage);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleIFrameModalMessage = (event: any) => {
        if(event?.data?.message === IFrameModalMessageIds.footerButton_action) {
            refs[event.data.value].current.click();
        }
    };

    if(showFooterButtons) {
        window.parent.postMessage({
            message: IFrameModalMessageIds.footerButtons, value: [
                //array of button props
                {
                    'id': "cancel",
                    'data-qa': (token !== null ? "token-" : "company-") + "cancel-button",
                    'variant': "outlined",
                    'children': t('cancel'),
                },
                {
                    'id': "saveAndClose",
                    'data-qa': (token !== null ? "token-" : "company-") + "save-close-button",
                    'variant': "contained",
                    'children': t('saveAndClose'),
                    'disabled': hasError
                }
            ]
        }, '*');
    } else {
        window.parent.postMessage({
            message: IFrameModalMessageIds.footerButtons, value: [
                {
                    'variant': "hidden",
                    'disabled': "true"
                }
            ]
        }, '*');
    }


    const closeReviewModal = () => {
        setReviewModalOpened(false);
        setShowFooterButtons(true);
        if(isInsideEmbark) {
            window.parent.postMessage({message: IFrameModalMessageIds.headerShowClose, value: true}, '*');
        }
    };

    const navigateToReviewPage = () => {
        closeReviewModal();
        navigate(RouteLink.Review,  {
            state: {
                clickCheckYourConfigurationButton: true,
            }
        });
    };

    const closeCancelModal = () => {
        setCancelModalOpened(false);
        setShowFooterButtons(true);
        if(isInsideEmbark) {
            window.parent.postMessage({message: IFrameModalMessageIds.headerShowClose, value: true}, '*');
        }
    };

    const reviewConfigurationModal = () => {
        if(!reviewModalOpened) {
            return null;
        }
        const modalHeader = <ModalHeader showClose title={t('review.configuration.modal.header')}
                                         onCloseClicked={() => closeReviewModal()}
                                         slotProps={{iconButton: {customAttributes: {'data-qa': 'review-configuration-modal-close-button'}}}}/>
        const modalFooter = (
            <ModalFooter>
                <ButtonContainer alignButtons="right">
                    <Button
                        data-qa="review-configuration-modal-confirm"
                        color="info"
                        id="review-configuration-modal-confirm"
                        variant="outlined"
                        onClick={() => {
                            setSuccessfulSyncConfiguration(false);
                            saveSettings(false);
                        }}>
                        {t('review.configuration.modal.later')}
                    </Button>
                    <Button
                        data-qa="review-configuration-modal-review.changes"
                        id="review-configuration-modal-review.changes"
                        onClick={() => navigateToReviewPage()}>
                        {t('review.configuration.modal.review.changes')}
                    </Button>
                </ButtonContainer>
            </ModalFooter>
        );

        return (
            <ConfirmationModal modalOpened={reviewModalOpened}
                               modalHeader={modalHeader}
                               modalFooter={modalFooter}
                               description={t('review.configuration.modal.description')}
                               dataQA={"review-configuration"}
            />
        );
    }

    const cancelConfigurationModal = () => {
        if(!cancelModalOpened) {
            return null;
        }
        const modalHeader = <ModalHeader showClose title={t('cancel.configuration.modal.header')}
                                         onCloseClicked={() => closeCancelModal()}
                                         slotProps={{iconButton: {customAttributes: {'data-qa': 'cancel-configuration-modal-close-button'}}}}/>
        const modalFooter = (
            <ModalFooter>
                <ButtonContainer alignButtons="right">
                    <Button
                        data-qa="cancel-configuration-modal-cancel"
                        id="cancel-configuration-modal-cancel"
                        onClick={() => closeCancelModal()}
                        variant="outlined">
                        {t('cancel.configuration.modal.cancel')}
                    </Button>
                    <Button
                        data-qa="cancel-configuration-modal-confirm"
                        color="info"
                        id="cancel-configuration-modal-confirm"
                        onClick={() => {
                            closeModal()
                        }}>
                        {t('cancel.configuration.modal.confirm')}
                    </Button>
                </ButtonContainer>
            </ModalFooter>
        );

        return (
            <ConfirmationModal modalOpened={cancelModalOpened}
                               modalHeader={modalHeader}
                               modalFooter={modalFooter}
                               warningIcon
                               description={t('cancel.configuration.modal.description')}
                               dataQA={"cancel-configuration"}
            />
        );
    }


    return (
        <>
            <Snackbar open={open} onClose={() => setOpen(false)}>
                <Alert severity="error" onClose={() => setOpen(false)}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            {<Box className="FooterBar" data-qa="footer-bar" sx={{display: isInsideEmbark ? 'none' : 'unset'}}>
                <ButtonContainer alignButtons="right">
                    {showFooterButtons &&
                        <><Button id="cancel"
                            data-qa="cancel-button"
                            variant="outlined"
                            ref={refs.cancel}
                            onClick={cancel}>
                            {t('cancel')}
                        </Button>
                            <Button id="saveAndClose"
                                data-qa="save-close-button"
                                ref={refs.saveAndClose}
                                onClick={saveAndClose}>
                                {t('saveAndClose')}
                            </Button>
                        </>
                    }
                </ButtonContainer>
            </Box>
            }
            {reviewConfigurationModal()}
            {cancelConfigurationModal()}
        </>
    )
};