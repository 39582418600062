import React from 'react'
import {Box, FormControlLabel, Switch, Typography} from "@emburse/embark-core";
import {useTranslation} from "react-i18next";
import {CustomerAndProjectSettings, GeneralSettings, RouteLink, UserSettings} from "enums";
import {ConfigurationContext} from "providers";
import secureLocalStorage from "react-secure-storage";
import {BottomNavBar, ViewContainer} from "components";
import {getGeneralFromLocalStorage} from "utils";

export const General = () => {
    const {t} = useTranslation();
    const {setConfiguration, configurationId, companyId} = React.useContext(ConfigurationContext);

    const [subsidiaries, setSubsidiaries] = React.useState(getGeneralFromLocalStorage(companyId, configurationId).subsidiariesEnabled);

    const [syncDepartment, setSyncDepartment] = React.useState(getGeneralFromLocalStorage(companyId, configurationId).syncDepartmentEnabled);
    const [syncClass, setSyncClass] = React.useState(getGeneralFromLocalStorage(companyId, configurationId).syncClassEnabled);
    const [syncLocation, setSyncLocation] = React.useState(getGeneralFromLocalStorage(companyId, configurationId).syncLocationEnabled);

    const subsidiariesChecked = (value: any) => {
        const subsidiariesChecked = value.target.checked;
        setSubsidiaries(subsidiariesChecked);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + GeneralSettings.SUBSIDIARIES_ENABLED, subsidiariesChecked);
        setConfiguration({
            general: {
                subsidiariesEnabled: subsidiariesChecked,
                syncDepartmentEnabled: syncDepartment,
                syncClassEnabled: syncClass,
                syncLocationEnabled: syncLocation
            }
        })
    };

    const syncDepartmentChecked = (value: any) => {
        const syncDepartmentChecked = value.target.checked;
        setSyncDepartment(syncDepartmentChecked);
        if(!syncDepartmentChecked) {
            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.PROJECTS_DEPARTMENT, "");
            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.VENDORS_DEPARTMENT, "");
        }
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + GeneralSettings.SYNC_DEPARTMENT_ENABLED, syncDepartmentChecked);
        setConfiguration({
            general: {
                subsidiariesEnabled: subsidiaries,
                syncDepartmentEnabled: syncDepartmentChecked,
                syncClassEnabled: syncClass,
                syncLocationEnabled: syncLocation
            }
        })
    };

    const syncClassChecked = (value: any) => {
        const syncClassChecked = value.target.checked;
        setSyncClass(syncClassChecked);
        if(!syncClassChecked) {
            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.PROJECTS_CLASS, "");
            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.VENDORS_CLASS, "");
        }
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + GeneralSettings.SYNC_CLASS_ENABLED, syncClassChecked);
        setConfiguration({
            general: {
                subsidiariesEnabled: subsidiaries,
                syncDepartmentEnabled: syncDepartment,
                syncClassEnabled: syncClassChecked,
                syncLocationEnabled: syncLocation
            }
        })
    };

    const syncLocationChecked = (value: any) => {
        const syncLocationChecked = value.target.checked;
        setSyncLocation(syncLocationChecked);
        if(!syncLocationChecked) {
            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + CustomerAndProjectSettings.PROJECTS_LOCATION, "");
            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.VENDORS_LOCATION, "");
        }
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + GeneralSettings.SYNC_LOCATION_ENABLED, syncLocationChecked);
        setConfiguration({
            general: {
                subsidiariesEnabled: subsidiaries,
                syncDepartmentEnabled: syncDepartment,
                syncClassEnabled: syncClass,
                syncLocationEnabled: syncLocationChecked
            }
        })
    };

    return <>
        <ViewContainer data-qa="general-settings-page">
            <Typography verticalSpacingSize={1} variant="h4"
                        data-qa="title-general-settings">{t('general')}</Typography>
            <Typography verticalSpacingSize={6}
                        data-qa="sub-title-general-settings">{t('subTitleGeneral')}</Typography>

            <Typography variant="h5"
                        verticalSpacingSize={2}
                        data-qa="subsidiary-title-general-settings">{t('subsidiaryTitleGeneral')}</Typography>

            <Typography verticalSpacingSize={2}
                        data-qa="subsidiary-sub-title-general-settings">{t('subsidiarySubTitleGeneral')}</Typography>

            <Box verticalSpacingSize={6}>
                <FormControlLabel
                    data-qa="subsidiary-general-settings"
                    checked={subsidiaries}
                    control={<Switch data-qa="switch-subsidiary-general-settings"
                                     color="primary"
                                     onChange={(event) => {
                                         subsidiariesChecked(event);
                                     }}/>}
                    label={<Typography variant="subtitle1"> {t('subsidiary')}</Typography>}/>
            </Box>

            <Typography variant="h5"
                        verticalSpacingSize={2}
                        data-qa="title-classification">{t('classification')}</Typography>

            <Typography verticalSpacingSize={2}
                        data-qa="sub-title-classification">{t('subTitleClassification')}</Typography>

            <Box verticalSpacingSize={2}>
                <FormControlLabel
                    data-qa="sync-department-general-settings"
                    checked={syncDepartment}
                    control={<Switch data-qa="switch-sync-department-general-settings" color="primary"/>}
                    onChange={(event) => {
                        syncDepartmentChecked(event);
                    }}
                    label={<Typography variant="subtitle1"> {t('syncDepartment')}</Typography>}/>
            </Box>

            <Box verticalSpacingSize={2}>
                <FormControlLabel
                    data-qa="sync-class-general-settings"
                    checked={syncClass}
                    control={<Switch data-qa="switch-sync-class-general-settings" color="primary"/>}
                    onChange={(event) => {
                        syncClassChecked(event)
                    }}
                    label={<Typography variant="subtitle1"> {t('syncClass')}</Typography>}/>
            </Box>

            <Box verticalSpacingSize={3}>
                <FormControlLabel
                    data-qa="sync-location-general-settings"
                    checked={syncLocation}
                    control={<Switch data-qa="switch-sync-location-general-settings" color="primary"/>}
                    onChange={(event) => {
                        syncLocationChecked(event)
                    }}
                    label={<Typography variant="subtitle1"> {t('syncLocation')}</Typography>}/>
            </Box>

        </ViewContainer>

        <BottomNavBar
            prevDataQA="back-to-account-credentials-button-navigation"
            prevText={t('accountCredentials')}
            prevRoutes={RouteLink.AccountCredentials}
            nextDataQA="next-to-users-button-navigation"
            nextText={t('users')}
            nextRoutes={RouteLink.Users}/>
    </>
}