import {Icon, Sidebar} from "@emburse/embark-core";
import React from "react";
import {AccountCredentialSettings, RouteLink} from "enums";
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConfigurationContext } from "providers";
import {CheckCircleIcon} from '@emburse/embark-icons';
import './NavBar.css';
import {getDefaultStringValue} from "utils";

export const NavBar = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {pathname} = useLocation();
    const {companyId, configurationId, netsuiteAuthentication, isInsideEmbark} = React.useContext(ConfigurationContext);
    const checkCircleIcon: any =  <Icon data-qa="account-credentials-check-circle-icon" iconUrl={CheckCircleIcon} style={{color: "#4CAF50"}}/>

    const authenticationNetsuiteStatus = getDefaultStringValue(companyId + "_" + configurationId + "_" + AccountCredentialSettings.AUTHENTICATION_NETSUITE_STATUS);
    const displayCircleIcon = authenticationNetsuiteStatus !== undefined && authenticationNetsuiteStatus === 'successful'  && !netsuiteAuthentication.inProgress;

    return <Sidebar
        className={"removeBorderRight"}
        variant="permanent"
        sx={[
            !isInsideEmbark && {
                '& > .MuiDrawer-paper' : {
                    top: '61px'
                }
            }
        ]}
        sidebarMenu={{
            menus: [
                {
                    tabMenu: {
                        sections: [
                            {
                                items: [
                                    {
                                        text: t('accountCredentials'),
                                        ...(displayCircleIcon? {icon: checkCircleIcon} : undefined),
                                        customAttr: {'data-qa': 'account-credentials-left-bar'},
                                        selected: (pathname === RouteLink.AccountCredentials || pathname === RouteLink.Home),
                                        onClick: () => {
                                            return navigate(RouteLink.AccountCredentials);
                                        }

                                    },
                                    {
                                        text: t('general'),
                                        customAttr: {'data-qa': 'general-left-bar'},
                                        selected: pathname === RouteLink.General,
                                        onClick: () => {
                                            return navigate(RouteLink.General);
                                        }

                                    },
                                    {
                                        text: t('users'),
                                        customAttr: {'data-qa': 'users-left-bar'},
                                        selected: pathname === RouteLink.Users,
                                        onClick: () => {
                                            return navigate(RouteLink.Users);
                                        }

                                    },
                                    {
                                        text: t('customersAndProjects'),
                                        customAttr: {'data-qa': 'customers-and-projects-left-bar'},
                                        selected: pathname === RouteLink.CustomersAndProjects,
                                        onClick: () => {
                                            return navigate(RouteLink.CustomersAndProjects);
                                        }

                                    },
                                    {
                                        text: t('expenses'),
                                        customAttr: {'data-qa': 'expenses-left-bar'},
                                        selected: pathname === RouteLink.Expenses,
                                        onClick: () => {
                                            return navigate(RouteLink.Expenses);
                                        }
                                    },
                                    {
                                        text: t('review'),
                                        customAttr: {'data-qa': 'review-left-bar'},
                                        selected: pathname === RouteLink.Review,
                                        onClick: () => {
                                            return navigate(RouteLink.Review);
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                }]
        }}/>
};