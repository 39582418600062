import { Box, CircularProgress, Typography } from "@emburse/embark-core";
import { Header } from "components";
import React from "react";


/**
 * This component will display the progress loading page.
 */
export const ConfigurationLoading = () => {

    return <>
        <Box verticalSpacingSize={10} verticalSpacingPosition="top">
            <Header/>
            <Typography align="center">
                <CircularProgress/>
            </Typography>
        </Box>
    </>
};
