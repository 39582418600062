import React from "react";
import { useTranslation } from 'react-i18next';

import { Box, Typography, useTheme } from "@emburse/embark-core";
import { ConfigurationContext } from "providers";
import { IFrameModalMessageIds } from "@emburse/embark-complex";

export const Header = () => {
    const {t, i18n} = useTranslation();
    const theme = useTheme();
    const {isInsideEmbark} = React.useContext(ConfigurationContext);
    const [title, setTitle] = React.useState<string>(t('titleDefault'));

    React.useEffect(() => {
        if ( isInsideEmbark ) {
            updateEmbarkModalTitle(t('netsuiteConnections'));
            window.parent.postMessage({message: IFrameModalMessageIds.headerShowClose, value: true}, '*');
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if ( i18n.resolvedLanguage ) {
            const newTitle = i18n.t('netsuiteConnections');
            updateEmbarkModalTitle(newTitle);
        }
    }, [i18n.resolvedLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        // Prepare body to have right background color for browsers who have a different theme
        document.body.style.backgroundColor = theme.palette.background.paper;        
    }, [theme?.palette]); // eslint-disable-line react-hooks/exhaustive-deps


    const HEIGHT = '60px';
    
    const headerSx = {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        lineHeight: HEIGHT,
        height: HEIGHT,
        backgroundColor: '#fff',
        zIndex: 1000 
    };

    const updateEmbarkModalTitle = (title:string) => {
        if ( isInsideEmbark ) {
            window.parent.postMessage({message: IFrameModalMessageIds.headerTitle, value: title}, '*');
        }
        setTitle(title);
    }
    
    return (
        <>
            {!isInsideEmbark && 
                <Box sx={headerSx}>
                    <Typography variant='h5' sx={{lineHeight: HEIGHT, marginLeft: '0.5em'}}>{title}</Typography>
                </Box>
            }
        </>
    );

};