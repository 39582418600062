import React from 'react'
import {Autocomplete, Box, Checkbox, FormControlLabel, Switch, TextField, Typography} from "@emburse/embark-core";
import {useTranslation} from "react-i18next";
import {ExpenseSettings, RouteLink, UserSettings} from "enums";
import {ConfigurationContext} from "providers";
import {BottomNavBar, SwitchTypography, ViewContainer} from "components";
import secureLocalStorage from "react-secure-storage";
import {Controller, FieldValues, useForm, useWatch} from "react-hook-form";
import {
    getDefaultValue, getExpensesFromLocalStorage, getHelperText, getMapUserSupervisorDefaultValue,
    getSyncClassFromLocalStorage,
    getSyncDepartmentFromLocalStorage,
    getSyncLocationFromLocalStorage,
    getUsersFromLocalStorage,
    getValueByKey, isValuesExistsKeyMap,
    lodash,
    textFieldPattern
} from "utils";
import {IEmployeesMapUserSupervisor, IMapUserIDs} from "models";

export const Users = () => {
    const {t} = useTranslation();
    const {
        setConfiguration,
        configurationId,
        companyId,
        featureFlags,
        netSuiteCustomEmployeeFields,
        netSuiteCustomVendorFields
    } = React.useContext(ConfigurationContext);

    const defaultUsers = getUsersFromLocalStorage(companyId, configurationId);

    const [syncEmployees, setSyncEmployees] = React.useState<boolean>(defaultUsers.syncEmployees as boolean);
    const [syncVendors, setSyncVendors] = React.useState<boolean>(defaultUsers.syncVendors as boolean);

    const syncDepartment = getSyncDepartmentFromLocalStorage(companyId, configurationId);
    const syncClass = getSyncClassFromLocalStorage(companyId, configurationId);
    const syncLocation = getSyncLocationFromLocalStorage(companyId, configurationId);

    const {
        register,
        clearErrors,
        control,
        formState,
        setValue,
        setError,
        watch,
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            employee: {
                filterEmployeesByType: defaultUsers.employee?.filterEmployeesByType,
                filterEmployeesByCustomFieldId: defaultUsers.employee?.filterEmployeesByCustomFieldId,
                mapUserIDs: defaultUsers.employee?.mapUserIDs,
                mapUserSupervisor: getMapUserSupervisorDefaultValue(defaultUsers.employee?.mapUserSupervisor),
                useCustomFieldForUserRole: defaultUsers.employee?.useCustomFieldForUserRole,
                synchronizeNetsuiteEmployeeCustomFields: defaultUsers.employee?.synchronizeNetsuiteEmployeeCustomFields,
                netsuiteCustomFieldsToImport: defaultUsers.employee?.netsuiteCustomFieldsToImport
            },
            vendor: {
                filterVendorByCustomFieldId: defaultUsers.vendor?.filterVendorByCustomFieldId,
                mapUserIDs: defaultUsers.vendor?.mapUserIDs,
                useCustomFieldForUserRole: defaultUsers.vendor?.useCustomFieldForUserRole,
                mapUserSupervisor: defaultUsers.vendor?.mapUserSupervisor,
                employee: defaultUsers.vendor?.employee,
                userVendorNumber: defaultUsers.vendor?.userVendorNumber,
                department: defaultUsers.vendor?.department,
                clazz: defaultUsers.vendor?.clazz,
                location: defaultUsers.vendor?.location,
                synchronizeNetsuiteVendorCustomFields: defaultUsers.vendor?.synchronizeNetsuiteVendorCustomFields,
                netsuiteCustomFieldsToImport: defaultUsers.vendor?.netsuiteCustomFieldsToImport
            }
        }
    });

    const MapUserIDs: IMapUserIDs =
        {
            accountNumber: t('netSuiteAccountNumber'),
            internalId: t('internalId'),
            externalId: t('externalId'),
            entityId: t('entityId')
        };

    const EmployeesMapUserSupervisor: IEmployeesMapUserSupervisor =
        {
            supervisor: t('netSuiteSupervisor'),
            expenseApprover: t('netSuiteExpenseApprover')
        };

    const {errors} = formState;

    const values = useWatch({control});
    React.useEffect(() => {
        saveInLocalStorage(values);
        setConfiguration({
            user: {
                syncEmployees: syncEmployees,
                syncVendors: syncVendors,
                employee: values.employee,
                vendor: values.vendor
            }
        });
    }, [values]);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        saveInLocalStorage(values);
        setConfiguration({
            user: {
                syncEmployees: syncEmployees,
                syncVendors: syncVendors,
                employee: values.employee,
                vendor: values.vendor
            }
        });
    }, [values.vendor?.mapUserSupervisor]);// eslint-disable-line react-hooks/exhaustive-deps

    const syncEmployeesChecked = (value: any) => {
        const syncEmployeesChecked = value.target.checked;
        setSyncEmployees(syncEmployeesChecked);

        if(!syncEmployeesChecked) {
            setValue("employee.filterEmployeesByType", []);
            setValue("employee.filterEmployeesByCustomFieldId", "");
            setValue("employee.mapUserIDs", "");
            setValue("employee.mapUserSupervisor", "supervisor");
            setValue("employee.useCustomFieldForUserRole", "");
            setValue("employee.synchronizeNetsuiteEmployeeCustomFields", false);
            setValue("employee.netsuiteCustomFieldsToImport", []);

            clearErrors("employee.filterEmployeesByCustomFieldId");
            clearErrors("employee.useCustomFieldForUserRole");

            saveEmployeesInLocalStorage(values.employee);

            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.EXPORT_EXPENSE_REPORT_ENABLED, false);
            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.EXPENSE_REPORT_FORM_ID, '');
            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.REPORT_NUMBER_PREFIX, '');
            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.EXPORT_EXPENSE_REPORT_AS_COMPLETE_ENABLED, false);
            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.SET_SUPERVISOR_APPROVAL_ENABLED, false);
            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.SET_ACCOUNTING_APPROVAL_ENABLED, false);
            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.PAYABLE_ACCOUNT, '');

            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.EXPENSE_REPORT_INCLUDING_CORPORATE_CARDS_ENABLED, false);
            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.CORPORATE_CARD_ACCOUNT, "");
        }
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.SYNC_EMPLOYEES, syncEmployeesChecked);
        setConfiguration({
            user: {
                syncEmployees: syncEmployeesChecked,
                syncVendors: syncVendors,
                employee: values.employee,
                vendor: values.vendor
            },
            expense: getExpensesFromLocalStorage(companyId, configurationId)
        })
    };

    const syncVendorsChecked = (value: any) => {
        const syncVendorsChecked = value.target.checked;
        setSyncVendors(syncVendorsChecked);

        if(!syncVendorsChecked) {
            setValue("vendor.filterVendorByCustomFieldId", "");
            setValue("vendor.mapUserIDs", "");
            setValue("vendor.useCustomFieldForUserRole", "");
            setValue("vendor.mapUserSupervisor", false);
            setValue("vendor.employee", "");
            setValue("vendor.userVendorNumber", "");
            setValue("vendor.department", "");
            setValue("vendor.clazz", "");
            setValue("vendor.location", "");
            setValue("vendor.synchronizeNetsuiteVendorCustomFields", false);
            setValue("vendor.netsuiteCustomFieldsToImport", []);

            clearErrors("vendor.filterVendorByCustomFieldId");
            clearErrors("vendor.employee");
            clearErrors("vendor.userVendorNumber");
            clearErrors("vendor.useCustomFieldForUserRole");
            clearErrors("vendor.department");
            clearErrors("vendor.clazz");
            clearErrors("vendor.location");

            saveVendorsInLocalStorage(values.vendor);

            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.EXPORT_AS_VENDOR_BILLS_ENABLED, false);
            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.VENDOR_BILL_FORM_ID, '');
            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.VENDOR_CREDIT_FORM_ID, '');
            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.VENDOR_BILL_APPROVAL_STATUS, '');
            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.VENDOR_BILL_CREDIT_CARDS_ENABLED, false);
        }

        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.SYNC_VENDORS, syncVendorsChecked);
        setConfiguration({
            user: {
                syncEmployees: syncEmployees,
                syncVendors: syncVendorsChecked,
                employee: values.employee,
                vendor: values.vendor
            },
            expense: getExpensesFromLocalStorage(companyId, configurationId)
        });
    };

    const synchronizeNetsuiteEmployeeCustomFieldsChecked = (value: any) => {
        setValue("employee.synchronizeNetsuiteEmployeeCustomFields", value.target.checked);
        if(!value.target.checked) {
            setValue("employee.netsuiteCustomFieldsToImport", []);
        }
    }

    const synchronizeNetsuiteVendorCustomFieldsChecked = (value: any) => {
        setValue("vendor.synchronizeNetsuiteVendorCustomFields", value.target.checked);
        if(!value.target.checked) {
            setValue("vendor.netsuiteCustomFieldsToImport", []);
        }
    }

    const saveInLocalStorage = (formData: FieldValues) => {
        saveEmployeesInLocalStorage(formData.employee);
        saveVendorsInLocalStorage(formData.vendor);
    };

    const saveEmployeesInLocalStorage = (employee: any) => {
        if(!lodash.isNil(employee.filterEmployeesByType)) {
            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.EMPLOYEES_FILTER_EMPLOYEES_BY_TYPE, employee.filterEmployeesByType);
        }
        if(!lodash.isNil(employee.filterEmployeesByCustomFieldId)) {
            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.EMPLOYEES_FILTER_EMPLOYEES_BY_CUSTOM_FIELD_ID, employee.filterEmployeesByCustomFieldId);
        }
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.EMPLOYEES_MAP_USER_IDS, employee.mapUserIDs);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.EMPLOYEES_MAP_USER_SUPERVISOR, employee.mapUserSupervisor);
        if(!lodash.isNil(employee.useCustomFieldForUserRole)) {
            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.EMPLOYEES_USE_NETSUITE_CUSTOM_FIELD_FOR_USER_ROLE, employee.useCustomFieldForUserRole);
        }
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.EMPLOYEES_SYNCHRONIZE_NETSUITE_EMPLOYEE_CUSTOM_FIELDS, employee.synchronizeNetsuiteEmployeeCustomFields);
        if(!lodash.isNil(employee.netsuiteCustomFieldsToImport)) {
            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.EMPLOYEES_NETSUITE_CUSTOM_FIELDS_TO_IMPORT, employee.netsuiteCustomFieldsToImport);
        }
    };

    const saveVendorsInLocalStorage = (vendor: any) => {
        if(!lodash.isNil(vendor.filterVendorByCustomFieldId)) {
            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.VENDORS_FILTER_VENDOR_BY_CUSTOM_FIELD_ID, vendor.filterVendorByCustomFieldId);
        }
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.VENDORS_MAP_USER_IDS, vendor.mapUserIDs);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.VENDORS_MAP_USER_SUPERVISOR, vendor.mapUserSupervisor);
        if(!lodash.isNil(vendor.employee)) {
            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.VENDORS_NETSUITE_EMPLOYEE, vendor.employee);
        }
        if(!lodash.isNil(vendor.userVendorNumber)) {
            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.VENDORS_NETSUITE_USER_VENDOR_NUMBER, vendor.userVendorNumber);
        }
        if(!lodash.isNil(vendor.useCustomFieldForUserRole)) {
            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.VENDORS_USE_NETSUITE_CUSTOM_FIELD_FOR_USER_ROLE, vendor.useCustomFieldForUserRole);
        }

        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.VENDORS_DEPARTMENT, vendor.department as string);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.VENDORS_CLASS, vendor.clazz as string);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.VENDORS_LOCATION, vendor.location as string);

        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.VENDORS_SYNCHRONIZE_NETSUITE_Vendor_CUSTOM_FIELDS, vendor.synchronizeNetsuiteVendorCustomFields);
        if(!lodash.isNil(vendor.netsuiteCustomFieldsToImport)) {
            secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + UserSettings.VENDORS_NETSUITE_CUSTOM_FIELDS_TO_IMPORT, vendor.netsuiteCustomFieldsToImport);
        }
    };

    const handleOnChangeCreate = (_event: React.ChangeEvent<{}>, newValue: any) => {
        setValue("employee.filterEmployeesByType", newValue);
    };

    return <>
        <ViewContainer data-qa="users-page">
            <Typography verticalSpacingSize={1} variant="h4" data-qa="title-users-page">{t('users')}</Typography>
            <Typography verticalSpacingSize={6} data-qa="sub-title-users">{t('subTitleUsers')}</Typography>

            <Box verticalSpacingSize={6}>
                <FormControlLabel
                    data-qa="sync-employees-users"
                    verticalSpacingSize={1}
                    checked={syncEmployees}
                    control={<Switch data-qa="switch-sync-employees-users" color="primary"/>}
                    onChange={(event) => {
                        syncEmployeesChecked(event)
                    }}
                    label={<Typography variant="h5">{t('syncEmployeesAsUsers')}</Typography>}/>

                <SwitchTypography data-qa="sync-employees-sub-title-users"
                                  value={t('syncEmployeesAsUsersSubTitleUsers')}/>

                {syncEmployees && <Box className="switchLeftMargin widthField">
                    <form data-qa="users-employees-form" onSubmit={(e) => {
                        e.preventDefault()
                    }}>

                        <Autocomplete id="filterEmployeesByTypeEmployees"
                                      data-qa="filter-employees-by-type-employees-users"
                                      onChange={handleOnChangeCreate}
                                      verticalSpacingSize={3}
                                      verticalSpacingPosition={"top"}
                                      multiple
                                      limitTags={5}
                                      options={values.employee?.filterEmployeesByType}
                                      autoHighlight
                                      value={values.employee?.filterEmployeesByType}
                                      filterOptions={(options, params) => {
                                          const filtered: string[] = [];
                                          if(params.inputValue !== '' && !params.inputValue.includes(' ') && !textFieldPattern.test(params.inputValue)) {
                                              filtered.push(
                                                  params.inputValue.toLowerCase()
                                              );
                                          }
                                          return filtered;
                                      }}
                                      label={t('filterEmployeesByType')}
                                      name="employee.filterEmployeesByType"
                                      customText={{noOptionsText: t('multipleTextFieldValidationMessage')}}
                                      slotProps={{
                                          clearIndicator: {'data-qa': 'filter-employees-by-type-clear-indicator'},
                                          popupIndicator: {'data-qa': 'filter-employees-by-type-popup-indicator'},
                                          popper: {'data-qa': 'filter-employees-by-type-popper'},
                                          paper: {'data-qa': 'filter-employees-by-type-paper'}
                                      }}
                                      FormHelperTextProps={{'data-qa': 'filter-employees-by-type-helperText'}}
                                      helperText={t('filterEmployeesByTypeHelperText')}
                        />

                        <TextField id="filterEmployeesByCustomFieldIdEmployees"
                                   data-qa="filter-employees-by-CustomField-id-employees-users"
                                   inputProps={{"data-qa": "inner-filter-employees-by-CustomField-id-employees-users"}}
                                   verticalSpacingSize={3}
                                   verticalSpacingPosition={"top"}
                                   type="text"
                                   label={t('filterEmployeesByCustomFieldId')}
                                   fullWidth
                                   {...register('employee.filterEmployeesByCustomFieldId',
                                       {
                                           validate: {
                                               Validate(value) {
                                                   if(value !== undefined) {
                                                       return (!textFieldPattern.test(value));
                                                   }
                                               }
                                           },
                                           onBlur(event: any) {
                                               if(event.target.value.length !== 0 && textFieldPattern.test(event.target.value)) {
                                                   setValue("employee.filterEmployeesByCustomFieldId", "");
                                               }
                                           }
                                       })}
                                   InputLabelProps={{shrink: !!watch('employee.filterEmployeesByCustomFieldId')}}
                                   error={!!errors.employee?.filterEmployeesByCustomFieldId}
                                   FormHelperTextProps={{'data-qa': 'filter-employees-by-CustomField-id-helperText'}}
                                   helperText={errors.employee?.filterEmployeesByCustomFieldId ? t('textFieldValidationMessage') : t('filterEmployeesByCustomFieldIdHelperText')}/>

                        <Controller
                            render={(props) => {
                                return (
                                    <Autocomplete id="mapUserIDsEmployees"
                                                  data-qa="map-user-ids-employees-users"
                                                  label={t('mapUserIDs')}
                                                  verticalSpacingSize={3}
                                                  verticalSpacingPosition={"top"}
                                                  options={Object.keys(MapUserIDs)}
                                                  getOptionLabel={(option) => {
                                                      return `${getValueByKey(MapUserIDs, option)}`;
                                                  }}
                                                  onChange={(_, data) => {
                                                      data = (data === null) ? '' : data;
                                                      return props.field.onChange(data);
                                                  }}
                                                  defaultValue={getDefaultValue(defaultUsers.employee?.mapUserIDs)}
                                                  slotProps={{
                                                      clearIndicator: {'data-qa': 'map-user-ids-employees-clear-indicator'},
                                                      popupIndicator: {'data-qa': 'map-user-ids-employees-popup-indicator'},
                                                      popper: {'data-qa': 'map-user-ids-employees-popper'},
                                                      paper: {'data-qa': 'map-user-ids-employees-paper'}
                                                  }}
                                                  FormHelperTextProps={{'data-qa': 'map-user-ids-employees-helperText'}}
                                                  helperText={t('mapUserIDsEmployeesHelperText')}/>
                                );
                            }}
                            control={control}
                            name="employee.mapUserIDs"
                        />

                        <Controller
                            render={(props) => {
                                return (
                                    <Autocomplete id="mapUserSupervisorEmployees"
                                                  data-qa="map-user-supervisor-employees-users"
                                                  label={t('mapUserSupervisor')}
                                                  verticalSpacingSize={3}
                                                  verticalSpacingPosition={"top"}
                                                  disableClearable
                                                  options={Object.keys(EmployeesMapUserSupervisor)}
                                                  getOptionLabel={(option) => {
                                                      return `${getValueByKey(EmployeesMapUserSupervisor, option)}`;
                                                  }}
                                                  onChange={(_, data) => {
                                                      return props.field.onChange(data);
                                                  }}
                                                  defaultValue={getMapUserSupervisorDefaultValue(defaultUsers.employee?.mapUserSupervisor)}
                                                  slotProps={{
                                                      clearIndicator: {'data-qa': 'map-user-supervisor-clear-indicator'},
                                                      popupIndicator: {'data-qa': 'map-user-supervisor-popup-indicator'},
                                                      popper: {'data-qa': 'map-user-supervisor-popper'},
                                                      paper: {'data-qa': 'map-user-supervisor-paper'}
                                                  }}
                                                  FormHelperTextProps={{'data-qa': 'map-user-supervisor-helperText'}}
                                                  helperText={t('mapUserSupervisorHelperText')}/>
                                );
                            }}
                            control={control}
                            name="employee.mapUserSupervisor"
                        />

                        <TextField id="useNetSuiteCustomFieldForUserRoleEmployees"
                                   data-qa="use-netSuite-custom-field-for-user-role-employees-users"
                                   inputProps={{"data-qa": "inner-use-netSuite-custom-field-for-user-role-employees-users"}}
                                   type="text"
                                   label={t('useNetSuiteCustomFieldForUserRole')}
                                   verticalSpacingSize={3}
                                   verticalSpacingPosition={"top"}
                                   fullWidth
                                   {...register('employee.useCustomFieldForUserRole',
                                       {
                                           validate: {
                                               Validate(value) {
                                                   if(value !== undefined) {
                                                       return (!textFieldPattern.test(value));
                                                   }
                                               }
                                           },
                                           onBlur(event: any) {
                                               if(event.target.value.length !== 0 && textFieldPattern.test(event.target.value)) {
                                                   setValue("employee.useCustomFieldForUserRole", "");
                                               }
                                           }

                                       })}
                                   InputLabelProps={{shrink: !!watch('employee.useCustomFieldForUserRole')}}
                                   error={!!errors.employee?.useCustomFieldForUserRole}
                                   FormHelperTextProps={{'data-qa': 'use-netSuite-custom-field-for-user-role-employees-helperText'}}
                                   helperText={errors.employee?.useCustomFieldForUserRole ? t('textFieldValidationMessage') : t('useNetSuiteCustomFieldForUserRoleEmployeesHelperText')}/>

                        {featureFlags.customFieldsFeatureEnabled && <FormControlLabel
                            data-qa="synchronize-netsuite-employee-custom-fields-employees"
                            checked={values.employee?.synchronizeNetsuiteEmployeeCustomFields}
                            verticalSpacingSize={3}
                            verticalSpacingPosition={"top"}
                            control={<Checkbox data-qa="checkbox-synchronize-netsuite-employee-custom-fields-employees"
                                               color="primary"/>}
                            onChange={(event) => {
                                synchronizeNetsuiteEmployeeCustomFieldsChecked(event)
                            }}
                            value={values.employee?.synchronizeNetsuiteEmployeeCustomFields}
                            label={<Typography
                                variant="subtitle1">{t('synchronizeNetsuiteEmployeeCustomFields')}</Typography>}/>
                        }

                        {featureFlags.customFieldsFeatureEnabled && values.employee?.synchronizeNetsuiteEmployeeCustomFields &&
                            <Box className="checkboxLeftMargin">
                                <Controller
                                    render={(props) => {
                                        return (
                                            <Autocomplete id="selectEmployeeNetsuiteCustomFieldsToImport"
                                                          data-qa="select-netsuite-custom-fields-to-import-employees"
                                                          label={t('selectNetsuiteCustomFieldsToImport')}
                                                          verticalSpacingSize={2}
                                                          verticalSpacingPosition={"top"}
                                                          multiple
                                                          showCheckbox
                                                          limitTags={5}
                                                          options={Array.from(netSuiteCustomEmployeeFields.keys())}
                                                          getOptionLabel={(option) => {
                                                              const optionLabel = netSuiteCustomEmployeeFields.get(option) as string;
                                                              if(optionLabel === undefined && option !== undefined) {
                                                                  return option + " (Inactive)";
                                                              }
                                                              return optionLabel;
                                                          }}
                                                          onChange={(_, data) => {
                                                              return props.field.onChange(data);
                                                          }}
                                                          value={defaultUsers.employee?.netsuiteCustomFieldsToImport ?? []}
                                                          error={isValuesExistsKeyMap(values.employee?.netsuiteCustomFieldsToImport, netSuiteCustomEmployeeFields)}
                                                          helperText={isValuesExistsKeyMap(values.employee?.netsuiteCustomFieldsToImport, netSuiteCustomEmployeeFields) ? t('inactiveNetSuiteCustomField') : ""}
                                                          slotProps={{
                                                              clearIndicator: {'data-qa': 'select-netsuite-custom-fields-to-import-clear-indicator-employees'},
                                                              popupIndicator: {'data-qa': 'select-netsuite-custom-fields-to-import-popup-indicator-employees'},
                                                              popper: {'data-qa': 'select-netsuite-custom-fields-to-import-popper-employees'},
                                                              paper: {'data-qa': 'select-netsuite-custom-fields-to-import-paper-employees'}
                                                          }}
                                            />
                                        );
                                    }}
                                    control={control}
                                    name="employee.netsuiteCustomFieldsToImport"
                                />
                            </Box>
                        }
                    </form>
                </Box>
                }
            </Box>

            <Box verticalSpacingSize={3}>
                <FormControlLabel
                    data-qa="sync-vendors-users"
                    checked={syncVendors}
                    verticalSpacingSize={1}
                    control={<Switch data-qa="switch-sync-vendors-users" color="primary"/>}
                    onChange={(event) => {
                        syncVendorsChecked(event)
                    }}
                    label={<Typography variant="h5">{t('syncVendorsAsUsers')}</Typography>}/>

                <SwitchTypography data-qa="sync-vendors-sub-title-users"
                                  value={t('syncVendorsAsUsersSubTitleUsers')}/>

                {syncVendors && <Box className="switchLeftMargin widthField">
                    <form data-qa="users-vendors-form" onSubmit={(e) => {
                        e.preventDefault()
                    }}>

                        <TextField id="filterVendorByCustomFieldIdVendors"
                                   data-qa="filter-vendor-by-Custom-field-id-vendors-users"
                                   inputProps={{"data-qa": "inner-filter-vendor-by-Custom-field-id-vendors-users"}}
                                   verticalSpacingSize={3}
                                   verticalSpacingPosition={"top"}
                                   type="text"
                                   label={t('filterVendorByCustomFieldId')}
                                   fullWidth
                                   {...register('vendor.filterVendorByCustomFieldId', {
                                       required: true,
                                       validate: {
                                           Validate(value) {
                                               return (value !== undefined && value?.trim().length !== 0 && !textFieldPattern.test(value));
                                           }
                                       },
                                       onBlur(event: any) {
                                           if(event.target.value.length === 0) {
                                               setError('vendor.filterVendorByCustomFieldId', {type: "required"});
                                           } else if(textFieldPattern.test(event.target.value)) {
                                               setError('vendor.filterVendorByCustomFieldId', {type: "pattern"});
                                               setValue("vendor.filterVendorByCustomFieldId", "");
                                           }
                                       }
                                   })}
                                   required
                                   InputLabelProps={{shrink: !!watch('vendor.filterVendorByCustomFieldId')}}
                                   FormHelperTextProps={{'data-qa': 'filter-vendor-by-Custom-field-id-helperText'}}
                                   helperText={getHelperText(t('filterVendorByCustomFieldIdHelperText'), t('filterVendorByCustomFieldIdWarningMessage'), t('textFieldValidationMessage'), errors.vendor?.filterVendorByCustomFieldId)}
                                   error={!!errors.vendor?.filterVendorByCustomFieldId}/>

                        <Controller
                            render={(props) => {
                                return (
                                    <Autocomplete id="mapUserIDsVendors"
                                                  data-qa="map-user-ids-vendors-users"
                                                  label={t('mapUserIDs')}
                                                  verticalSpacingSize={3}
                                                  verticalSpacingPosition={"top"}
                                                  options={Object.keys(MapUserIDs)}
                                                  getOptionLabel={(option) => {
                                                      return `${getValueByKey(MapUserIDs, option)}`;
                                                  }}
                                                  onChange={(_, data) => {
                                                      data = (data === null) ? '' : data;
                                                      return props.field.onChange(data);
                                                  }}
                                                  defaultValue={getDefaultValue(defaultUsers.vendor?.mapUserIDs)}
                                                  slotProps={{
                                                      clearIndicator: {'data-qa': 'map-user-ids-clear-indicator'},
                                                      popupIndicator: {'data-qa': 'map-user-ids-popup-indicator'},
                                                      popper: {'data-qa': 'map-user-ids-popper'},
                                                      paper: {'data-qa': 'map-user-ids-paper'}
                                                  }}
                                                  FormHelperTextProps={{'data-qa': 'map-user-ids-vendors-helperText'}}
                                                  helperText={t('mapUserIDsVendorsHelperText')}/>
                                );
                            }}
                            control={control}
                            name="vendor.mapUserIDs"
                        />

                        <FormControlLabel
                            data-qa="map-user-supervisor-vendors-users"
                            verticalSpacingSize={3}
                            verticalSpacingPosition={"top"}
                            control={
                                <Controller
                                    render={(props) => {
                                        return (
                                            <Checkbox data-qa="checkbox-map-user-supervisor-vendors-users"
                                                      color="primary"
                                                      checked={values.vendor?.mapUserSupervisor}
                                                      onChange={(event: any) => {
                                                          setValue("vendor.employee", "");
                                                          setValue("vendor.userVendorNumber", "");
                                                          clearErrors("vendor.employee");
                                                          clearErrors("vendor.userVendorNumber");
                                                          return props.field.onChange(event);
                                                      }}
                                            />
                                        );
                                    }}
                                    control={control}
                                    defaultValue={values.vendor?.mapUserSupervisor}
                                    name="vendor.mapUserSupervisor"
                                />
                            }
                            label={t('mapUserSupervisor')}
                        />


                        {values.vendor?.mapUserSupervisor &&
                            <Box className="checkboxLeftMargin">
                                <TextField id="netSuiteEmployeeVendors"
                                           data-qa="netSuite-employee-vendors-users"
                                           inputProps={{"data-qa": "inner-netSuite-employee-vendors-users"}}
                                           verticalSpacingSize={3}
                                           verticalSpacingPosition={"top"}
                                           type="text"
                                           label={t('netSuiteEmployee')}
                                           fullWidth
                                           {...register('vendor.employee',
                                               {
                                                   validate: {
                                                       Validate(value) {
                                                           if(value !== undefined) {
                                                               return (!textFieldPattern.test(value));
                                                           }
                                                       }
                                                   },
                                                   onBlur(event: any) {
                                                       if(event.target.value.length !== 0 && textFieldPattern.test(event.target.value)) {
                                                           setValue("vendor.employee", "");
                                                       }
                                                   }

                                               })}
                                           InputLabelProps={{shrink: !!watch('vendor.employee')}}
                                           error={!!errors.vendor?.employee}
                                           FormHelperTextProps={{'data-qa': 'netSuite-employee-vendors-helperText'}}
                                           helperText={errors.vendor?.employee ? t('textFieldValidationMessage') : t('netSuiteEmployeeHelperText')}/>

                                <TextField id="netSuiteUserVendorNumberVendors"
                                           data-qa="netsuite-user-vendor-number-vendors-users"
                                           inputProps={{"data-qa": "inner-netsuite-user-vendor-number-vendors-users"}}
                                           verticalSpacingSize={3}
                                           verticalSpacingPosition={"top"}
                                           type="text"
                                           label={t('netSuiteUserVendorNumber')}
                                           fullWidth
                                           {...register('vendor.userVendorNumber',
                                               {
                                                   validate: {
                                                       Validate(value) {
                                                           if(value !== undefined) {
                                                               return (!textFieldPattern.test(value));
                                                           }
                                                       }
                                                   },
                                                   onBlur(event: any) {
                                                       if(event.target.value.length !== 0 && textFieldPattern.test(event.target.value)) {
                                                           setValue("vendor.userVendorNumber", "");
                                                       }
                                                   }

                                               })}
                                           InputLabelProps={{shrink: !!watch('vendor.userVendorNumber')}}
                                           error={!!errors.vendor?.userVendorNumber}
                                           FormHelperTextProps={{'data-qa': 'netsuite-user-vendor-number-vendors-users-helperText'}}
                                           helperText={errors.vendor?.userVendorNumber ? t('textFieldValidationMessage') : t('netSuiteUserVendorNumberHelperText')}/>
                            </Box>}

                        <TextField id="useNetSuiteCustomFieldForUserRoleVendors"
                                   data-qa="use-netSuite-custom-field-for-user-role-vendors-users"
                                   inputProps={{"data-qa": "inner-use-netSuite-custom-field-for-user-role-vendors-users"}}
                                   type="text"
                                   label={t('useNetSuiteCustomFieldForUserRole')}
                                   verticalSpacingSize={4}
                                   verticalSpacingPosition={"top"}
                                   fullWidth
                                   {...register('vendor.useCustomFieldForUserRole',
                                       {
                                           validate: {
                                               Validate(value) {
                                                   if(value !== undefined) {
                                                       return (!textFieldPattern.test(value));
                                                   }
                                               }
                                           },
                                           onBlur(event: any) {
                                               if(event.target.value.length !== 0 && textFieldPattern.test(event.target.value)) {
                                                   setValue("vendor.useCustomFieldForUserRole", "");
                                               }
                                           }

                                       })}
                                   InputLabelProps={{shrink: !!watch('vendor.useCustomFieldForUserRole')}}
                                   error={!!errors.vendor?.useCustomFieldForUserRole}
                                   FormHelperTextProps={{'data-qa': 'use-netSuite-custom-field-for-user-role-vendors-helperText'}}
                                   helperText={errors.vendor?.useCustomFieldForUserRole ? t('textFieldValidationMessage') : t('useNetSuiteCustomFieldForUserRoleVendorsHelperText')}/>

                        {syncDepartment && <TextField id="vendorDepartment"
                                                      data-qa="department-vendors-users"
                                                      inputProps={{"data-qa": "inner-department-vendors-users"}}
                                                      verticalSpacingSize={3}
                                                      verticalSpacingPosition={"top"}
                                                      type="text"
                                                      {...register('vendor.department',
                                                          {
                                                              validate: {
                                                                  Validate(value) {
                                                                      if(value !== undefined) {
                                                                          return (!textFieldPattern.test(value));
                                                                      }
                                                                  }
                                                              },
                                                              onBlur(event: any) {
                                                                  if(event.target.value.length !== 0 && textFieldPattern.test(event.target.value)) {
                                                                      setValue("vendor.department", "");
                                                                  }
                                                              }
                                                          })}
                                                      InputLabelProps={{shrink: !!watch('vendor.department')}}
                                                      label={t('department')}
                                                      fullWidth
                                                      error={!!errors.vendor?.department}
                                                      FormHelperTextProps={{'data-qa': 'department-vendors-users-helperText'}}
                                                      helperText={errors.vendor?.department ? t('textFieldValidationMessage') : t('vendorDepartmentHelperText')}/>
                        }
                        {syncClass && <TextField id="vendorClass"
                                                 data-qa="class-vendors-users"
                                                 inputProps={{"data-qa": "inner-class-vendors-users"}}
                                                 verticalSpacingSize={3}
                                                 verticalSpacingPosition={"top"}
                                                 type="text"
                                                 {...register('vendor.clazz',
                                                     {
                                                         validate: {
                                                             Validate(value) {
                                                                 if(value !== undefined) {
                                                                     return (!textFieldPattern.test(value));
                                                                 }
                                                             }
                                                         },
                                                         onBlur(event: any) {
                                                             if(event.target.value.length !== 0 && textFieldPattern.test(event.target.value)) {
                                                                 setValue("vendor.clazz", "");
                                                             }
                                                         }
                                                     })}
                                                 InputLabelProps={{shrink: !!watch('vendor.clazz')}}
                                                 label={t('class')}
                                                 fullWidth
                                                 error={!!errors.vendor?.clazz}
                                                 FormHelperTextProps={{'data-qa': 'class-vendors-users-helperText'}}
                                                 helperText={errors.vendor?.clazz ? t('textFieldValidationMessage') : t('vendorClassHelperText')}/>
                        }
                        {syncLocation && <TextField id="vendorLocation"
                                                    data-qa="location-vendors-users"
                                                    inputProps={{"data-qa": "inner-location-vendors-users"}}
                                                    verticalSpacingSize={3}
                                                    verticalSpacingPosition={"top"}
                                                    type="text"
                                                    {...register('vendor.location',
                                                        {
                                                            validate: {
                                                                Validate(value) {
                                                                    if(value !== undefined) {
                                                                        return (!textFieldPattern.test(value));
                                                                    }
                                                                }
                                                            },
                                                            onBlur(event: any) {
                                                                if(event.target.value.length !== 0 && textFieldPattern.test(event.target.value)) {
                                                                    setValue("vendor.location", "");
                                                                }
                                                            }
                                                        })}
                                                    InputLabelProps={{shrink: !!watch('vendor.location')}}
                                                    label={t('location')}
                                                    fullWidth
                                                    error={!!errors.vendor?.location}
                                                    FormHelperTextProps={{'data-qa': 'location-vendors-users-helperText'}}
                                                    helperText={errors.vendor?.location ? t('textFieldValidationMessage') : t('vendorLocationHelperText')}/>
                        }


                        {featureFlags.customFieldsFeatureEnabled && <FormControlLabel
                            data-qa="synchronize-netsuite-vendor-custom-fields-vendors"
                            checked={values.vendor?.synchronizeNetsuiteVendorCustomFields}
                            verticalSpacingSize={3}
                            verticalSpacingPosition={"top"}
                            control={<Checkbox data-qa="checkbox-synchronize-netsuite-vendor-custom-fields-vendors"
                                               color="primary"/>}
                            onChange={(event) => {
                                synchronizeNetsuiteVendorCustomFieldsChecked(event)
                            }}
                            value={values.vendor?.synchronizeNetsuiteVendorCustomFields}
                            label={<Typography
                                variant="subtitle1">{t('synchronizeNetsuiteVendorCustomFields')}</Typography>}/>
                        }

                        {featureFlags.customFieldsFeatureEnabled && values.vendor?.synchronizeNetsuiteVendorCustomFields &&
                            <Box className="checkboxLeftMargin">
                                <Controller
                                    render={(props) => {
                                        return (
                                            <Autocomplete id="selectVendorNetsuiteCustomFieldsToImport"
                                                          data-qa="select-netsuite-custom-fields-to-import-vendors"
                                                          label={t('selectNetsuiteCustomFieldsToImport')}
                                                          verticalSpacingSize={2}
                                                          verticalSpacingPosition={"top"}
                                                          multiple
                                                          showCheckbox
                                                          limitTags={5}
                                                          options={Array.from(netSuiteCustomVendorFields.keys())}
                                                          getOptionLabel={(option) => {
                                                              const optionLabel = netSuiteCustomVendorFields.get(option) as string;
                                                              if(optionLabel === undefined && option !== undefined) {
                                                                  return option + ' (Inactive)'
                                                              }
                                                              return optionLabel;
                                                          }}
                                                          onChange={(_, data) => {
                                                              return props.field.onChange(data);
                                                          }}
                                                          value={defaultUsers.vendor?.netsuiteCustomFieldsToImport ?? []}
                                                          error={isValuesExistsKeyMap(values.vendor?.netsuiteCustomFieldsToImport, netSuiteCustomVendorFields)}
                                                          helperText={isValuesExistsKeyMap(values.vendor?.netsuiteCustomFieldsToImport, netSuiteCustomVendorFields) ? t('inactiveNetSuiteCustomField') : ""}
                                                          slotProps={{
                                                              clearIndicator: {'data-qa': 'select-netsuite-custom-fields-to-import-clear-indicator-vendors'},
                                                              popupIndicator: {'data-qa': 'select-netsuite-custom-fields-to-import-popup-indicator-vendors'},
                                                              popper: {'data-qa': 'select-netsuite-custom-fields-to-import-popper-vendors'},
                                                              paper: {'data-qa': 'select-netsuite-custom-fields-to-import-paper-vendors'}
                                                          }}
                                            />
                                        );
                                    }}
                                    control={control}
                                    name="vendor.netsuiteCustomFieldsToImport"
                                />
                            </Box>
                        }

                    </form>
                </Box>
                }
            </Box>
        </ViewContainer>

        <BottomNavBar
            prevDataQA="back-to-general-button-navigation"
            prevText={t('general')}
            prevRoutes={RouteLink.General}
            nextDataQA="next-to-customers-and-projects-button-navigation"
            nextText={t('customersAndProjects')}
            nextRoutes={RouteLink.CustomersAndProjects}/>
    </>
}