export enum ExpenseSettings {
    EXPENSE_IMPORT_CATEGORIES_ENABLED = 'expenseSettings.importExpenseCategoriesEnabled',
    SYNCHRONIZE_NETSUITE_EXPENSE_CUSTOM_FIELDS_ENABLED = 'expenseSettings.synchronizeNetsuiteExpenseCustomFieldsEnabled',
    NETSUITE_CUSTOM_FIELDS_TO_IMPORT = 'expenseSettings.netsuiteCustomFieldsToImport',
    MULTIPLE_CURRENCIES_ENABLED = 'expenseSettings.multipleCurrenciesEnabled',
    NETSUITE_SUITE_TAXES_ENABLED = 'expenseSettings.netsuiteSuiteTaxesEnabled',
    NETSUITE_TAXABLE_REGIONS = 'expenseSettings.netsuiteTaxableRegions',
    OVERRIDE_FOREIGN_AMOUNT_AND_EXCHANGE_RATE_ENABLED = 'expenseSettings.overrideForeignAmountAndExchangeRateEnabled',

    EXPORT_EXPENSE_REPORT_ENABLED = 'expenseSettings.exportExpenseReports',
    EXPENSE_REPORT_FORM_ID = 'expenseSettings.expenseReportFormId',
    REPORT_NUMBER_PREFIX = 'expenseSettings.reportNumberPrefix',
    EXPORT_EXPENSE_REPORT_AS_COMPLETE_ENABLED = 'expenseSettings.exportExpenseReportAsCompleteEnabled',
    SET_SUPERVISOR_APPROVAL_ENABLED = 'expenseSettings.setSupervisorApprovalEnabled',
    SET_ACCOUNTING_APPROVAL_ENABLED = 'expenseSettings.setAccountingApprovalEnabled',
    PAYABLE_ACCOUNT = 'expenseSettings.payableAccount',

    EXPORT_COMPANY_PAID_EXPENSES_ENABLED = 'expenseSettings.exportCompanyPaidExpensesEnabled',
    EXPENSE_REPORT_INCLUDING_CORPORATE_CARDS_ENABLED = 'expenseSettings.expenseReportIncludingCorporateCardsEnabled',
    CORPORATE_CARD_ACCOUNT = 'expenseSettings.corporateCardAccount',

    EXPORT_AS_VENDOR_BILLS_ENABLED = 'expenseSettings.exportAsVendorBills',
    VENDOR_BILL_FORM_ID = 'expenseSettings.vendorBillFormId',
    VENDOR_CREDIT_FORM_ID = 'expenseSettings.vendorCreditFormId',
    VENDOR_BILL_APPROVAL_STATUS = 'expenseSettings.vendorBillApprovalStatus',
    VENDOR_BILL_CREDIT_CARDS_ENABLED = 'expenseSettings.vendorBillCreditCardsEnabled',
    EXPORT_EXPENSE_RECEIPTS_ENABLED = 'expenseSettings.expenseReceiptsEnabled',
    EXPORT_RECEIPTS_FOLDER_ID = 'expenseSettings.receiptsFolderId'
}