import {BaseAPI} from "./api";
import {IConfiguration, IGetConfigurations, ISaveConfigurationResponse} from "models";
import {saveInLocalStorage} from "utils";

export class ConfigurationWithTokenService {
    api: BaseAPI;

    constructor(token: string) {
        this.api = new BaseAPI(process.env.REACT_APP_API_URL, 'Bearer ' + token, false);
    }

    async getConfigurations(): Promise<IGetConfigurations> {
        const resp = await this.api.get("/v1/configurations/companies");
        const data: IGetConfigurations = resp?.success ? resp.data : null;
        if(resp?.success) {
            saveInLocalStorage(data);
        }
        return data;
    };

    async saveConfigurations(configuration: IConfiguration, id: string): Promise<ISaveConfigurationResponse> {
        const resp = await this.api.put("/v1/configurations/" + id, configuration);
        let expiredToken = false;
        if(!resp?.success) {
            const status = resp.data.response.status;
            const errorMessage = resp.data.response?.data;

            if(errorMessage !== undefined && status === 401 && errorMessage.includes("Token is expired")) {
                expiredToken = true;
            }
        }
        const response: ISaveConfigurationResponse = {
            success: resp?.success,
            expiredToken: expiredToken
        };
        return response;
    };


}