import {Grid, Icon, Modal, Typography} from "@emburse/embark-core";
import React from "react";
import {WarningIcon} from "@emburse/embark-icons";
import {IConfirmationModal} from "models";

const warningColor = '#FF9800';

export const ConfirmationModal = (props: IConfirmationModal) => {

    return (
        <Modal open={props.modalOpened} modalHeader={props.modalHeader} modalFooter={props.modalFooter} slotProps={{
            root: {'data-qa': 'modal-root-' + props.dataQA},
            backdrop: {'data-qa': 'modal-backdrop-' + props.dataQA},
            ModalBox: {'data-qa': 'modal-container-' + props.dataQA},
            ModalBodyBox: {'data-qa': 'modal-body-container-' + props.dataQA},
            ModalHeaderBox: {'data-qa': 'modal-header-container-' + props.dataQA}
        }}>
            <div style={{display: "flex"}}>
                {props.warningIcon && <Grid item style={{flex: "1"}}>
                    <Icon data-qa="warningIcon" iconUrl={WarningIcon} style={{color: warningColor}}/>
                </Grid>
                }
                <Grid item style={{flex: "16"}}>
                    <Typography variant="body1">{props.description}</Typography>
                </Grid>
            </div>
        </Modal>
    );
};