
import {initReactI18next} from 'react-i18next';
import embark_en from './embark/en-US.json';
import embark_es from './embark/es-ES.json';
import embark_fr from './embark/fr-CA.json';

import { i18nEmptyInstance } from '@emburse/embark-locale';
import resource_en from './resources.json';
import resource_es from './resources_es.json';
import resource_fr from './resources_fr.json';

const resources = {
    'en-US': {translation: { ...embark_en, ...resource_en}},
    'es-ES': {translation: { ...embark_es, ...resource_es}},
    'fr-CA': {translation: { ...embark_fr, ...resource_fr}},
};

export const i18nInstance = (lng = 'en-US') => {
  const instance = i18nEmptyInstance();
  instance.use(initReactI18next)
    .init({
        resources: resources,
        react: {
          useSuspense: false
        },
        lng: lng,
        fallbackLng: ['en-US', 'dev'], // use en if detected lng is not available

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
          escapeValue: false // react already safes from xss
        }
    });
  return instance;
};