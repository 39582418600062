import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from "views/App/App";
import React from "react";
import { ConfigurationProvider } from 'providers';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const queryParameters =  new URLSearchParams(window.location.search);
const tokenParam = queryParameters.get('token');
const token = tokenParam ?? '';

root.render(
    <ConfigurationProvider token={token}>
        <App />
    </ConfigurationProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
