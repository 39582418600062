import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { RouteLink } from "enums";


/**
 * This component will redirect the user back to the home page.
 * In the future, we can show an actual 404 page by removing the `history.replace` and returning a component instead.
 */
export const Error404 = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // Remove this useEffect if you want to return an actual 404 page instead
    useEffect(() => {
        console.error(`404: Path '${location.pathname}' does not exist.  Redirecting to home page.`);
        navigate(RouteLink.Home);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // Replace the null with an actual component if you want to show a 404 Error to the user
    return null;
};
