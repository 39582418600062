export enum UserSettings {
    SYNC_EMPLOYEES = 'users.syncEmployees',
    SYNC_VENDORS = 'users.syncVendors',

    EMPLOYEES_FILTER_EMPLOYEES_BY_TYPE = 'users.employees.filterEmployeesByType',
    EMPLOYEES_FILTER_EMPLOYEES_BY_CUSTOM_FIELD_ID = 'users.employees.filterEmployeesByCustomFieldId',
    EMPLOYEES_MAP_USER_IDS = 'users.employees.mapUserIDs',
    EMPLOYEES_MAP_USER_SUPERVISOR = 'users.employees.mapUserSupervisor',
    EMPLOYEES_USE_NETSUITE_CUSTOM_FIELD_FOR_USER_ROLE = 'users.employees.useNetSuiteCustomFieldForUserRole',
    EMPLOYEES_SYNCHRONIZE_NETSUITE_EMPLOYEE_CUSTOM_FIELDS = 'users.employees.synchronizeNetsuiteEmployeeCustomFields',
    EMPLOYEES_NETSUITE_CUSTOM_FIELDS_TO_IMPORT = 'users.employees.netsuiteCustomFieldsToImport',

    VENDORS_FILTER_VENDOR_BY_CUSTOM_FIELD_ID = 'users.vendors.filterVendorByCustomFieldId',
    VENDORS_MAP_USER_IDS = 'users.vendors.mapUserIDs',
    VENDORS_MAP_USER_SUPERVISOR = 'users.vendors.mapUserSupervisor',
    VENDORS_NETSUITE_EMPLOYEE = 'users.vendors.netSuiteEmployee',
    VENDORS_NETSUITE_USER_VENDOR_NUMBER = 'users.vendors.netSuiteUserVendorNumber',
    VENDORS_USE_NETSUITE_CUSTOM_FIELD_FOR_USER_ROLE = 'users.vendors.useNetSuiteCustomFieldForUserRole',
    VENDORS_DEPARTMENT = 'users.vendors.department',
    VENDORS_CLASS = 'users.vendors.class',
    VENDORS_LOCATION = 'users.vendors.location',
    VENDORS_SYNCHRONIZE_NETSUITE_Vendor_CUSTOM_FIELDS = 'users.vendors.synchronizeNetsuiteVendorCustomFields',
    VENDORS_NETSUITE_CUSTOM_FIELDS_TO_IMPORT = 'users.vendors.netsuiteCustomFieldsToImport'
}